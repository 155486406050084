import React, {useEffect} from "react";
import AuthService from "./AuthService";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";

const IdentityInit: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.indexOf("logout_callback") < 0) {
            AuthService.tryRefreshAuthAndDispatch(dispatch).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default IdentityInit;