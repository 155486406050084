export enum PlayerClass {
    Adventure = "adventure",
    Trade = "trade",
    Battle = "battle"
}

export interface PersonLocationModel {
    location: string;
    person: string;
}
