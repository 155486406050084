export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';

interface AuthenticateUserAction {
    type: typeof AUTHENTICATE_USER;
    userInfo: {
        userId: string;
        displayName: string;
        refreshToken: string;
        accessToken: string;
        idToken: string;
        groups: string[];
    };
}

export type Action = AuthenticateUserAction;