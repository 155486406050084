import React from "react";
import {IDocumentLine, LineItem} from "./DocumentLine";
import {ShipModel} from "../../model/ship";
import {Col} from "react-bootstrap";
import styles from "./ShipLine.module.scss";
import InlineLink from "../InlineLink";
import {PlayerClass} from "../../model/common";
import SkillLink from "../SkillLink";
import Level from "../Level";

const ShipLine: IDocumentLine<{ship: ShipModel}> = props => {
    const {ship, noSeparator, noTooltip, hideIcon} = props;

    return <LineItem noSeparator={noSeparator}>
        <Col xs={5} sm={7} lg={4}>
            <div className={styles.shipLink}>
                <InlineLink to={ship}
                            hideIcon={hideIcon}
                            suppressTooltip={noTooltip}/>
            </div>
        </Col>

        <Col xs={7} sm={5} lg={3}>
            <div className={styles.levelReq}>
                <Level clazz={PlayerClass.Adventure} level={ship.advReq}/>
            </div>
            <div className={styles.levelReq}>
                <Level clazz={PlayerClass.Trade} level={ship.tradeReq}/>
            </div>
            <div className={styles.levelReq}>
                <Level clazz={PlayerClass.Battle} level={ship.battleReq}/>
            </div>
        </Col>

        <Col xs={{span: 10, offset: 1}} lg={{span: 5, offset: 0}}>
            {ship.skills.map((skill, i) =>
                <SkillLink key={i}
                           to={skill.skill}
                           showName={false}/>)}
        </Col>
    </LineItem>;
};

export default ShipLine;