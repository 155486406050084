import React from "react";

export enum ShipStatType {
    VerticalSail,
    HorizontalSail,
    Rowing,
    Durability,
    TurnSpeed,
    WaveResistance,
    Armour,
    Sailor,
    Cannon,
    Cargo
}

interface IShipStatIconProps {
    type: ShipStatType
}

const ShipStatIcon: React.FC<IShipStatIconProps> = props => {
    const imageParams = typeToImageParams(props.type);

    return <img src={require(`../assets/ship/${imageParams.key}.png`)}
                alt={imageParams.key}
                title={imageParams.title}/>
};

interface IImageParams {
    key: string;
    title: string;
}

function typeToImageParams(type: ShipStatType): IImageParams {
    switch (type) {
        case ShipStatType.VerticalSail:
            return {key: "v_sail", title: "Vertical sail"};
        case ShipStatType.HorizontalSail:
            return {key: "h_sail", title: "Horizontal sail"};
        case ShipStatType.Rowing:
            return {key: "row_power", title: "Row power"};
        case ShipStatType.Durability:
            return {key: "durability", title: "Durability"};
        case ShipStatType.TurnSpeed:
            return {key: "turn_speed", title: "Turn speed"};
        case ShipStatType.WaveResistance:
            return {key: "wave_resistance", title: "Wave resistance"};
        case ShipStatType.Armour:
            return {key: "armour", title: "Armour"};
        case ShipStatType.Sailor:
            return {key: "sailor", title: "Sailors required/cabin"};
        case ShipStatType.Cannon:
            return {key: "cannon", title: "Cannon chambers"};
        case ShipStatType.Cargo:
            return {key: "cargo", title: "Hold"};
    }
}

export default ShipStatIcon;