import React from "react";
import {IDocumentLine, LineItem} from "./DocumentLine";
import {isRefinementPossible, SkillModel} from "../../model/skill";
import {Col} from "react-bootstrap";
import SkillLink from "../SkillLink";
import styles from "./SkillLine.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/pro-duotone-svg-icons";

const SkillLine: IDocumentLine<{skill: SkillModel}> = props => {
    const {skill, noSeparator, noTooltip} = props;

    return <LineItem noSeparator={noSeparator}>
        <Col xs={11} lg={4}>
            <SkillLink to={skill} suppressTooltip={noTooltip}/>
        </Col>

        <Col xs={1} lg={1} className={styles.refinedSkillIconContainer}>
            {isRefinementPossible(skill) && <FontAwesomeIcon icon={faCrown}
                                                             title="Skill refinement possible"
                                                             className={styles.refinedSkillIcon} />}
        </Col>

        <Col xs={{span: 10, offset: 2}} lg={{span: 7, offset: 0}}
             className={styles.skillDescription}>
            {skill.description}
        </Col>
    </LineItem>;
};

export default SkillLine;

