import React, {ChangeEvent} from "react";

import Checkbox from "@material-ui/core/Checkbox";

type InputChange = ChangeEvent<HTMLInputElement>;

interface ICheckboxProps {
    value: boolean;
    onChange: (newValue: boolean) => void;
}

const AstrolabeCheckbox: React.FC<ICheckboxProps> = props => {
    const {value, onChange} = props;

    return <Checkbox color="default"
                     checked={value}
                     onChange={(ev: InputChange) =>
                         onChange(ev.target.checked)}/>;
};

export default AstrolabeCheckbox;