import {JobModel} from "../../model/job";
import React from "react";
import JobService from "../../services/JobService";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import JobImage from "../../fragments/JobImage";
import {faCheckSquare, faEdit, faGraduationCap, faInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import styles from "./JobViewer.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SkillLink from "../../fragments/SkillLink";
import InlineLink from "../../fragments/InlineLink";
import {faExclamationSquare, faStar} from "@fortawesome/pro-duotone-svg-icons";
import PageHeaders from "../../widgets/PageHeaders";
import {formatDucats, isTouchDevice} from "../../utils";
import InfoPanel from "../../fragments/InfoPanel";
import Level from "../../fragments/Level";
import {PlayerClass} from "../../model/common";
import Ducats from "../../fragments/Ducats";
import {IDataProps, withObservableDataLoader} from "../../hoc/DataLoader";
import AssetImage from "../../fragments/AssetImage";

const JobViewer: React.FC<IDataProps<JobModel>> = props => {
    let job = props.data;
    if (!job) return null;

    return <>
        <PageHeaders subject={job}/>

        <InfoPanel title={job.name} icon={<JobImage class={job.subtype}/>}>
            <Row>
                {/* Left column - description, requirements and endorsement */}
                <Col md={7}>
                    <InfoPanelSection icon={faInfoCircle} text="Description">
                        {job.description}
                    </InfoPanelSection>

                    <InfoPanelSection icon={faCheckSquare} text="Acquisition requirements">
                        <Row>
                            <Col xs={4}>
                                <Level clazz={PlayerClass.Adventure} level={job.advReq}/>
                            </Col>
                            <Col xs={4}>
                                <Level clazz={PlayerClass.Trade} level={job.tradeReq}/>
                            </Col>
                            <Col xs={4}>
                                <Level clazz={PlayerClass.Battle} level={job.battleReq}/>
                            </Col>
                            <Col xs={4}>
                                <Level clazz="total" level={job.totalReq}/>
                            </Col>
                            <Col>
                                <Ducats amount={job.cost}/>
                                &nbsp;
                                {!isTouchDevice() && job.cost && <OverlayTrigger placement="right"
                                                overlay={<Tooltip id="job-cost-warning">
                                                    <div className="text-left">
                                                        Base cost when changing to a job using an endorsement card.
                                                        <br/>
                                                        Otherwise, cost is 20x higher ({formatDucats(job.cost * 20)}).
                                                    </div>
                                                </Tooltip>}>
                                    <FontAwesomeIcon icon={faExclamationSquare}/>
                                </OverlayTrigger>}
                            </Col>
                        </Row>
                    </InfoPanelSection>

                    {job.endorsement && <>
                        <InfoPanelSection icon={faEdit} text="Endorsement">
                            <InlineLink to={job.endorsement}/>

                            {job.quest && <Row>
                                <Col xs={12} xl={7}>
                                    <InlineLink to={job.quest}/>
                                </Col>

                                <Col xs={{span: 10, offset: 2}} xl={{span: 5, offset: 0}}>
                                    {job.quest.requiredSkills && job.quest.requiredSkills.map(s =>
                                        <div key={s.skill.key} className={styles.skillRequirementBox}>
                                            <SkillLink key={s.skill.key} to={s} showName={false}/>
                                        </div>)}
                                </Col>
                            </Row>}
                        </InfoPanelSection>
                    </>}

                    <InfoPanelSection icon={faGraduationCap} text="Academic skills">
                    {job.academicSkills && job.academicSkills.map(skill =>
                        <Row key={skill.key}
                             className="mb-1">
                            <Col>
                                <AssetImage item={skill}/>
                                &nbsp;
                                <InlineLink to={skill} hideIcon/>
                            </Col>
                        </Row>)}
                    {job.academicSkills === undefined && <>None</>}
                    </InfoPanelSection>
                </Col>

                {/* Right column - skills */}
                <Col md={5} className="mt-3 mt-md-0">
                    {job.expertSkill && <>
                        <InfoPanelSection icon={faStar} text="Expert skill" faClass={styles.expSkill}>
                            <SkillLink to={job.expertSkill}/>
                        </InfoPanelSection>
                    </>}

                    <InfoPanelSection icon={faStar} text="Favoured skills" faClass={styles.favSkill}>
                        {job.favouredSkills.map(s => <Row key={s.key}>
                            <Col>
                                <SkillLink to={s}/>
                            </Col>
                        </Row>)}
                    </InfoPanelSection>
                </Col>
            </Row>
        </InfoPanel>
    </>;
};

export default withObservableDataLoader<JobModel, {key: string}>(
    JobViewer,
    props => JobService.getJobWithCache(props.match.params.key),
    (props, prevProps) =>
        props.match.params.key !== prevProps.match.params.key
);
