import React from "react";
import {IDataProps, withPromiseDataLoader} from "../../hoc/DataLoader";
import WikiService from "../../services/WikiService";
import WikiModel from "../../model/wiki";
import AstrolabeMarkdown from "../../fragments/AstrolabeMarkdown";
import {AppState} from "../../store/state";
import {useSelector} from "react-redux";
import {Col, Row} from "react-bootstrap";
import styles from "./WikiViewer.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/pro-duotone-svg-icons";
import {Link} from "react-router-dom";
import {canEditWiki} from "../../permissions";
import ColoredIconButton from "../../fragments/ColoredIconButton";
import {blue} from "@material-ui/core/colors";

const WikiViewer: React.FC<IDataProps<WikiModel>> = props => {
    const wikiPage = props.data;
    const canEdit = useSelector<AppState, boolean>(
        state => state && canEditWiki(state.userInfo));

    const EditButton = ColoredIconButton(blue);

    return <>
        <Row className={styles.titleRow + " mb-2"}>
            <Col>
                <h1 className="float-left">{wikiPage.title}</h1>

                {canEdit && <Link to={`/wiki/${wikiPage.key}/edit`} className="float-right">
                    <EditButton size="small"
                                title="Edit">
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </EditButton>
                </Link>}
            </Col>
        </Row>

        <AstrolabeMarkdown source={wikiPage.content}/>
    </>;
};

export default withPromiseDataLoader<WikiModel, {key: string}>(
    WikiViewer,
    props => WikiService.getWikiPage(props.match.params.key),
    (props, prevProps) => props.match.params.key !== prevProps.match.params.key
);