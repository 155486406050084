import React from "react";
import styles from "./TooltipCommon.module.scss";
import {JobModel} from "../../model/job";
import JobService from "../../services/JobService";
import {Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SkillLink from "../SkillLink";
import {faStar} from "@fortawesome/pro-duotone-svg-icons";
import TooltipBase, {withObservableTarget} from "./TooltipBase";
import {isEmpty} from "lodash";
import Level from "../Level";
import {PlayerClass} from "../../model/common";
import Ducats from "../Ducats";
import {IDataProps} from "../../hoc/DataLoader";

const JobTooltip: React.FC<IDataProps<JobModel>> = props => {
    const job = props.data;

    return <TooltipBase title={job.name} isLoading={false}>
        <TooltipBase.ContentRow>
            {job.description}
        </TooltipBase.ContentRow>

        <TooltipBase.ContentRow noCol>
            <Col xs={4}>
                <Level clazz={PlayerClass.Adventure} level={job.advReq}/>
            </Col>
            <Col xs={4}>
                <Level clazz={PlayerClass.Trade} level={job.tradeReq}/>
            </Col>
            <Col xs={4}>
                <Level clazz={PlayerClass.Battle} level={job.battleReq}/>
            </Col>
            <Col xs={4}>
                <Level clazz="total" level={job.totalReq}/>
            </Col>
            <Col>
                <Ducats amount={job.cost}/>
            </Col>
        </TooltipBase.ContentRow>

        {!isEmpty(job.expertSkill) && <TooltipBase.ContentRow noCol>
            <Col xs={1}>
                <FontAwesomeIcon icon={faStar} className={styles.expSkill}/>
            </Col>
            <Col className="ml-2">
                <SkillLink to={job.expertSkill} showName={false} suppressTooltip/>
            </Col>
        </TooltipBase.ContentRow>}

        <TooltipBase.ContentRow noCol>
            <Col xs={1}>
                <FontAwesomeIcon icon={faStar} className={styles.favSkill}/>
            </Col>
            <Col className="ml-2">
                {job.favouredSkills.map(s =>
                    <SkillLink key={s.key} to={s} showName={false} suppressTooltip/>
                )}
            </Col>
        </TooltipBase.ContentRow>
    </TooltipBase>;
};

export default withObservableTarget(
    JobTooltip,
    props => JobService.getJobWithCache(props.key)
);