import React, {useEffect, useState} from "react";
import styles from "./NewsEditor.module.scss";
import commonStyles from "../../styles/common.module.scss";
import NewsService from "../../services/NewsService";
import {useHistory, useRouteMatch} from "react-router";
import {Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";
import {AppState} from "../../store/state";
import PageHeaders from "../../widgets/PageHeaders";
import {navigate} from "../../utils";
import Loading from "../../fragments/Loading";
import {Button, TextField} from "@material-ui/core";
import NewsPost from "../../fragments/NewsPost";
import {NewsPostModel} from "../../model/news";

interface INewsEditorMatch {
    news_id: string
}

type StateSelect = {
    idToken: string | undefined;
    displayName: string | undefined;
};

const NewsEditor: React.FC = () => {
    const {idToken, displayName} = useSelector<AppState, StateSelect>(
        state => state && state.userInfo
            ? state.userInfo as StateSelect
            : {idToken: undefined, displayName: undefined});

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");

    const history = useHistory();
    const match = useRouteMatch<INewsEditorMatch>();

    useEffect(() => {
        const {news_id} = match.params;

        if (news_id !== undefined) {
            NewsService.getNewsPost(news_id).then(post => {
                setTitle(post.title);
                setContent(post.content);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [match.params]);

    function handlePostSubmit() {
        let {news_id} = match.params;
        if (news_id === undefined) {
            news_id = "new";
        }

        NewsService.postNews(
            news_id,
            title,
            content,
            idToken!)
            .then(() => navigate(history));
    }

    if (isLoading) {
        return <Loading/>;
    }

    const previewPost: NewsPostModel = {
        title,
        content,
        id: "new",
        poster: {
            displayName: displayName || "unknown"
        },
        postDate: new Date(Date.now())
    };

    return <>
        <PageHeaders title="Editing news post"/>

        <Row>
            <Col>
                <Row>
                    <Col className={styles.titleEditContainer}>
                        <TextField value={title}
                                   label="News title"
                                   onChange={event => setTitle(event.target.value)}
                                   className={commonStyles.fullWidth}/>

                        <Button variant="outlined"
                                onClick={handlePostSubmit}
                                className="ml-2">
                            Post
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <TextField multiline
                                   variant="outlined"
                                   value={content}
                                   label="News content"
                                   onChange={event => setContent(event.target.value)}
                                   className={commonStyles.fullWidth}/>
                    </Col>
                </Row>
            </Col>

            <Col>
                <Row>
                    <Col className={styles.postPreview}>
                        <NewsPost post={previewPost}
                                  forceCanEdit={false}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;
};

export default NewsEditor;
