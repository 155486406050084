import {useHistory, useRouteMatch} from "react-router";
import {JobModel} from "../../model/job";
import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Loading from "../../fragments/Loading";
import JobService from "../../services/JobService";
import {navigate, upperCaseFirst} from "../../utils";
import styles from "./JobBrowser.module.scss";
import PageHeaders from "../../widgets/PageHeaders";
import InfoPanel from "../../fragments/InfoPanel";
import JobLine from "../../fragments/lines/JobLine";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {BaseSkillModel} from "../../model/skill";
import MultiSkillSelector from "../../widgets/MultiSkillSelector";
import SmartButton from "../../widgets/SmartButton";
import { ButtonGroup } from "@material-ui/core";

interface IJobBrowserMatch {
    subtype: string;
}

interface IButtonDef {
    subtype: string | undefined;
    label: string;
    baseColor: string;
}

const BUTTON_DEFS: IButtonDef[] = [
    {
        subtype: undefined,
        label: "All",
        baseColor: "#888888"
    },
    {
        subtype: "adventure",
        label: "Adventure",
        baseColor: "#338833"
    },
    {
        subtype: "trade",
        label: "Trade",
        baseColor: "#999933"
    },
    {
        subtype: "battle",
        label: "Battle",
        baseColor: "#AA3333"
    }
];

const JobBrowser: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [skillsSelected, setSkillsSelected] = useState<BaseSkillModel[]>([]);
    const [jobs, setJobs] = useState<JobModel[]>([]);

    const history = useHistory();
    const match = useRouteMatch<IJobBrowserMatch>();

    const {subtype} = match.params;

    function renderHeaders() {
        if (subtype === undefined) {
            return <PageHeaders title="Jobs"/>;
        } else {
            return <PageHeaders title={`${upperCaseFirst(subtype)} jobs`}/>;
        }
    }

    function isSearchValid(): boolean {
        return subtype !== undefined || skillsSelected.length > 0;
    }

    function loadJobs(): void {
        const skills = skillsSelected.length > 0 ? skillsSelected.map(s => s.key) : undefined;

        if (subtype === undefined && skills === undefined) {
            setJobs([]);
            return;
        }

        setIsLoading(true);
        JobService.getJobs(subtype, skills)
            .then(jobs => {
                setJobs(jobs);
                setIsLoading(false);
            });
    }

    useEffect(loadJobs, [subtype, skillsSelected]);

    return <>
        {renderHeaders()}

        <InfoPanel title="Job browser">
            <InfoPanelSection noHeader>
                Browse jobs by selecting a category and/or filtering by expert/favoured skills.
            </InfoPanelSection>

            <InfoPanelSection noHeader>
                <ButtonGroup>
                    {BUTTON_DEFS.map(btn =>
                        <SmartButton key={btn.label}
                                     baseColor={btn.baseColor}
                                     isSelected={btn.subtype === subtype}
                                     onClick={() => navigate(history, "jobs", btn.subtype)}
                                     className={styles.subclassBtn}>
                            {btn.label}
                        </SmartButton>)}
                </ButtonGroup>

                <Row className="mt-1">
                    <Col>
                        <MultiSkillSelector placeholder="Filter by expert/favoured skills"
                                            subtypes={["adventure", "trade", "battle"]}
                                            selectedSkills={skillsSelected}
                                            onSelectionChanged={setSkillsSelected}/>
                    </Col>
                </Row>
            </InfoPanelSection>
        </InfoPanel>

        {isLoading && <Loading/>}

        {!isLoading && !isSearchValid() &&
        <div className={styles.resultsInformation}>
            At least one specific selection is required to perform a search.
        </div>}

        {!isLoading && <Container>
            {jobs.map(job =>
                <JobLine key={job.name}
                         job={job}
                         hideIcon/>)}
        </Container>}
    </>;
};

export default JobBrowser;
