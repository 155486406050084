import {QuestModel} from "../model/quest";
import BackendService from "./BackendService";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface IGetQuestsRequest {
    questGiver?: string,
    questLocations?: string[]
}

const QuestService = {
    getQuest(key: string): Promise<QuestModel[]> {
        return BackendService.get<QuestModel[]>(`/quest/${key}`)
            .then(response => response.data);
    },

    getQuestWithCache(key: string): Observable<QuestModel[]> {
        return BackendService.getObservableWithCache<QuestModel[]>(`/quest/${key}`)
            .pipe(map(response => response.data));
    },

    getQuests(request: IGetQuestsRequest): Promise<QuestModel[]> {
        return BackendService.post<QuestModel[]>("/quests", request)
            .then(response => response.data);
    }
};

export default QuestService;