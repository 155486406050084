import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import store from "./store/store";
import IdentityInit from "./services/IdentityInit";
import { BrowserRouter as Router } from 'react-router-dom';

import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

import Analytics from "./widgets/Analytics";
import {blue} from "@material-ui/core/colors";
import {BreakpointProvider} from "./widgets/BreakpointProvider";
import {NoSsr} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: blue,
        type: "dark"
    },
});

const queries = {
    sm: '(min-width: 576px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 992px)',
    xl: '(min-width: 1200px)'
};

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <NoSsr defer>
                <IdentityInit/>
                <Analytics/>
            </NoSsr>
            <ThemeProvider theme={theme}>
                <BreakpointProvider queries={queries}>
                    <App/>
                </BreakpointProvider>
            </ThemeProvider>
        </Provider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
