import React from "react";

export const canRender = <B, P extends B>(
    WrappedComponent: React.ComponentType<{data: P}>,
    canRender: (data: B) => boolean,
    canRenderSpecific: (data: P) => boolean = () => true) =>
    (props: {data: B}) =>
    {
        const {data} = props;

        if (canRender(data) && canRenderSpecific(data as P)) {
            return <WrappedComponent data={data as P}/>
        } else {
            return null;
        }
    };