import React from "react";

const NotImplemented: React.FC = () => {
    return <div>
        <h1>404 &mdash; You have sailed off the edge of the world!</h1>

        Sorry, the document type you're trying to open is not yet implemented by the developer of Astrolabe.
        <br/>
        Please stay tuned!
    </div>;
};

export default NotImplemented;