import {LinkableDocumentModel} from "../model/document";
import BackendService from "./BackendService";

export interface ISearchService {
    search(term: string,
           types: string[],
           subtypes: string[] | undefined,
           expand: boolean | undefined): Promise<LinkableDocumentModel[]>;
}

class SearchService implements ISearchService {
    search(term: string,
           types: string[],
           subtypes: string[] | undefined = undefined,
           expand: boolean | undefined = false): Promise<LinkableDocumentModel[]> {
        const joinedTypes = types.join(',');

        let url = `/search/${joinedTypes}/${term}`;
        if (subtypes) {
            const joinedSubtypes = subtypes.join(',');
            url += `?subtypes=${joinedSubtypes}`;
        }

        if (expand) {
            url += "?expand=true"
        }

        return BackendService.get<LinkableDocumentModel[]>(url)
            .then(response => response.data);
    }
}

export default new SearchService();