import React from "react";
import styles from "./UnderConstruction.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faConstruction} from "@fortawesome/pro-duotone-svg-icons";

const UnderConstruction: React.FC<{}> = props => {
    return <Container className={styles.underConstructionBar + " mb-2"}>
        <Row>
            <div className={styles.underConstructionIconContainer + " ml-2"}>
                <FontAwesomeIcon icon={faConstruction}/>
            </div>
            <Col>
                <div className={styles.underConstructionTitle}>
                    Under construction!
                </div>

                <div className={styles.underConstructionNotice}>
                    {props.children}
                </div>
            </Col>
            <div className={styles.underConstructionIconContainer + " mr-2"}>
                <FontAwesomeIcon icon={faConstruction}/>
            </div>
        </Row>
    </Container>;
};

export default UnderConstruction;