import React, {
    useState,
    useEffect,
    createContext,
    useContext
} from 'react';

const defaultValue = {};

export interface IBreakpointQueries {
    sm: string;
    md: string;
    lg: string;
    xl: string;
}

export interface IBreakpointMatches {
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

export const BreakpointContext = createContext<IBreakpointMatches>(defaultValue as any);

const BreakpointProvider: React.FC<{children: any, queries: IBreakpointQueries}> = props => {
    const queries = props.queries as any;

    const [queryMatch, setQueryMatch] = useState<IBreakpointMatches>({} as any);

    useEffect(() => {
        const mediaQueryLists: any = {};
        const keys = Object.keys(queries);
        let isAttached = false;

        const handleQueryListener = () => {
            const updatedMatches = keys.reduce((acc: any, media: string) => {
                acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
                return acc;
            }, {});
            setQueryMatch(updatedMatches)
        };

        if (window && window.matchMedia) {
            const matches: any = {};
            keys.forEach(media => {
                if (typeof queries[media] === 'string') {
                    mediaQueryLists[media] = window.matchMedia(queries[media]);
                    matches[media] = mediaQueryLists[media].matches
                } else {
                    matches[media] = false
                }
            });
            setQueryMatch(matches);
            isAttached = true;
            keys.forEach(media => {
                if(typeof queries[media] === 'string') {
                    mediaQueryLists[media].addListener(handleQueryListener)
                }
            });
        }

        return () => {
            if (isAttached) {
                keys.forEach(media => {
                    if(typeof queries[media] === 'string') {
                        mediaQueryLists[media].removeListener(handleQueryListener)
                    }
                });
            }
        }
    }, [queries]);

    return (
        <BreakpointContext.Provider value={queryMatch}>
            {props.children}
        </BreakpointContext.Provider>
    )
};

function useBreakpoints() {
    const context = useContext(BreakpointContext);
    if (context === defaultValue) {
        throw new Error('useBreakpoint must be used within BreakpointProvider');
    }
    return context;
}

export {useBreakpoints, BreakpointProvider};