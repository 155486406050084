import React from "react";
import {DocumentModel} from "../model/document";
import SearchService from "../services/SearchService";
import {Container} from "react-bootstrap";
import DocumentLine from "../fragments/lines/DocumentLine";
import UnderConstruction from "../fragments/UnderConstruction";
import {ALLOWED_SEARCH_TYPES} from "../model/search";
import {IDataProps, withPromiseDataLoader} from "../hoc/DataLoader";

const Search: React.FC<IDataProps<DocumentModel[]>> = props => {
    const {data} = props;
    return <Container>
        <UnderConstruction>
            Search feature of Astrolabe is under construction.
            Range of displayed information as well as layout may change.
        </UnderConstruction>

        {data.map(doc => <DocumentLine key={doc.type + '/' + doc.subtype + '/' + doc.key}
                                       target={doc}/>)}

        {data.length === 0 && <div>
            Search did not return any results.
        </div>}
    </Container>;
};

export default withPromiseDataLoader<DocumentModel[], {query: string}>(
    Search,
    props => SearchService.search(
        props.match.params.query,
        ALLOWED_SEARCH_TYPES,
        undefined,
        true),
    (props, prevProps) => props.match.params.query !== prevProps.match.params.query
);