import {ShipModel} from "../model/ship";
import BackendService from "./BackendService";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

interface GetShipsRequest {
    shipType?: string | undefined;
    skills?: string[] | undefined;
    sortBy?: string | undefined;
    sortByDirection?: 1 | -1 | undefined;
}

const ShipService = {
    getShip(key: string): Promise<ShipModel> {
        return BackendService.get<ShipModel>(`/ship/${key}`)
            .then(response => response.data);
    },

    getShipWithCache(key: string): Observable<ShipModel> {
        return BackendService.getObservableWithCache<ShipModel>(`/ship/${key}`)
            .pipe(map(response => response.data));
    },

    getShips(shipType: string | undefined, skillsKeys: string[] | undefined): Promise<ShipModel[]> {
        let request: GetShipsRequest = {};

        if (shipType) {
            request.shipType = shipType;
            request.sortBy = (shipType === 'adventure' ? 'adv' : shipType) + 'Req';
            request.sortByDirection = -1;
        } else {
            request.sortBy = 'name';
            request.sortByDirection = 1;
        }

        if (skillsKeys) {
            request.skills = skillsKeys;
        }

        return BackendService.post<ShipModel[]>(`/ships`, request)
            .then(response => response.data);
    }
};

export default ShipService;