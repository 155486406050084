import React from "react";
import styles from "./EditorNotice.module.scss";
import {Col, Container, Row} from "react-bootstrap";

const EditorNotice: React.FC = props =>
    <Container>
        <Row className={styles.notice + " mb-2"}>
            <Col>
                {props.children}
            </Col>
        </Row>
    </Container>;

export default EditorNotice;