import React from "react";
import {TradeGoodModel} from "../../model/trade-good";
import TooltipBase, {withObservableTarget} from "./TooltipBase";
import TradeGoodService from "../../services/TradeGoodService";
import {IDataProps} from "../../hoc/DataLoader";

const TradeGoodTooltip: React.FC<IDataProps<TradeGoodModel>> = props => {
    const tradeGood = props.data;

    return <TooltipBase title={tradeGood.name} isLoading={false}>
        <TooltipBase.ContentRow>
            <em>
                {tradeGood.category}

                {tradeGood.localSpecialty && <>
                    &nbsp;&mdash;&nbsp;
                    {tradeGood.localSpecialty}
                </>}
            </em>
        </TooltipBase.ContentRow>

        <TooltipBase.ContentRow>
            {tradeGood.description}
        </TooltipBase.ContentRow>
    </TooltipBase>;
};

export default withObservableTarget(
    TradeGoodTooltip,
    props => TradeGoodService.getTradeGoodWithCache(props.key)
);