import {NewsPostModel} from "../../model/news";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import styles from "./News.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import NewsPost from "../../fragments/NewsPost";
import {AppState} from "../../store/state";
import {useSelector} from "react-redux";
import NewsService from "../../services/NewsService";
import {canEditNews} from "../../permissions";
import ColoredIconButton from "../../fragments/ColoredIconButton";
import {green} from "@material-ui/core/colors";
import {withObservableDataLoader, IDataProps} from "../../hoc/DataLoader";

const News: React.FC<IDataProps<NewsPostModel[]>> = props => {
    const news = props.data;
    const canAdd = useSelector<AppState, boolean>(
        state => state && canEditNews(state.userInfo));

    if (news.length === 0) {
        return <Row>
            <Col>
                No news available. How odd.
            </Col>
        </Row>;
    }

    const AddPostButton = ColoredIconButton(green);

    return <>
        <Row>
            <Col xs={10}>
                <h3>Astrolabe news</h3>
            </Col>

            <Col xs={2} className="text-right">
                {canAdd && <Link to="/news/new">
                    <AddPostButton size="small"
                                   title="New post">
                        <FontAwesomeIcon icon={faPlus}/>
                    </AddPostButton>
                </Link>}
            </Col>
        </Row>

        {news.map(n => <div key={n.id} className={styles.newsPostsBoxRow}>
            <NewsPost post={n}/>
        </div>)}
    </>;
};

export default withObservableDataLoader<NewsPostModel[]>(
    News,
    () => NewsService.getFrontNews()
);