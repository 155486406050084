import React from "react";
import {QuestModel} from "../../model/quest";
import {Col} from "react-bootstrap";
import InlineLink from "../InlineLink";
import SkillLink from "../SkillLink";
import {IDocumentLine, LineItem} from "./DocumentLine";
import styles from "./QuestLine.module.scss";

const QuestLine: IDocumentLine<{quest: QuestModel}> = props => {
    const {quest, noSeparator, noTooltip, hideIcon} = props;

    return <LineItem noSeparator={noSeparator}>
        <Col xs={12} md={6} lg={5}>
            <InlineLink to={quest}
                        suppressTooltip={noTooltip}
                        hideIcon={hideIcon}/>
        </Col>

        <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 0}} lg={{span: 3, offset: 0}}>
            {quest.requiredSkills && quest.requiredSkills.map(s =>
                <div key={s.skill.key} className={styles.skillRequirementBox}>
                    <SkillLink key={s.skill.key} to={s} showName={false}/>
                </div>)}
        </Col>

        <Col xs={{span: 10, offset: 2}} md={{span: 6, offset: 6}} lg={{span: 4, offset: 0}}>
            {quest.discovery && <InlineLink to={quest.discovery}/>}
        </Col>
    </LineItem>;
};

export default QuestLine;