import React from "react";
import styles from "./RateBar.module.scss";

interface IRateBarProps {
    value: number;
    maxValue?: number;
    color: string;
}

const RateBar: React.FC<IRateBarProps> = props => {
    const {value, maxValue, color} = props;
    const width = Math.min(100 * value / (maxValue || 100), 100.0);

    return <div className={styles.rateBarContainer}>
        <div className={styles.rateBar}>
            <div className={styles.rateValueBar}
                 style={{
                     width: width + "%",
                     backgroundColor: color
                 }}>
            </div>
        </div>

        <div className={styles.rateValue}>
            {value}
        </div>
    </div>;
};

export default RateBar;