import React, {useEffect, useRef, useState} from "react";
import styles from "./ScrollToTopButton.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltToTop} from "@fortawesome/pro-duotone-svg-icons";

const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);

    const intervalId = useRef(0);
    const scrollPosition = useRef(0);

    function scrollToTop() {
        intervalId.current = window.setInterval(scrollStep, 10);
        setIsVisible(false);
    }

    function scrollStep() {
        if (scrollElement === null) {
            clearInterval(intervalId.current);
            return;
        }

        if (scrollElement.scrollTop === 0) {
            clearInterval(intervalId.current);
            intervalId.current = 0;
        }

        const scrollAmount = Math.max(20, scrollElement.scrollTop / 10);

        scrollElement.scroll(0, scrollElement.scrollTop - scrollAmount);
    }

    function scrollHandler(ev: Event): void {
        const {target} = ev;
        const newScrollPosition = (target as HTMLElement).scrollTop;

        let isVisible = false;

        if (newScrollPosition < scrollPosition.current && newScrollPosition > 0 && intervalId.current === 0) {
            isVisible = true;
        }

        scrollPosition.current = newScrollPosition;
        setIsVisible(isVisible);
    }

    useEffect(() => {
        //FIXME: this is a bit of hacky, but no better way to get a ref to another element
        const appContainerParent = document.getElementById("appContainer")!.parentElement;

        setScrollElement(appContainerParent);
        appContainerParent!.addEventListener("scroll", scrollHandler);

        return () => scrollElement!.removeEventListener("scroll", scrollHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let className = styles.scrollTopTopButton;

    if (isVisible) {
        className += " " + styles.visible;
    } else {
        className += " " + styles.invisible;
    }

    return <>
        <div className={className} title="Scroll to top" onClick={scrollToTop}>
            <div className={styles.arrowContainer}>
                <FontAwesomeIcon icon={faArrowAltToTop} size="2x"/>
            </div>
        </div>
    </>;
};

export default ScrollToTopButton;