import React from "react";
import styles from "./InfoPanel.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/pro-duotone-svg-icons";
import {Button, withStyles} from "@material-ui/core";

interface IInfoPanelProps {
    title: string;
    icon?: any;
    showEditButton?: boolean;
    onEditButtonClick?: () => void;
    noAutoPart?: boolean;
}

type IInfoPanel = React.FC<IInfoPanelProps> & {
    Part: React.FC<{innerClassName?: string}>
}

const InfoPanel: IInfoPanel = props => {
    const {title, icon, showEditButton, onEditButtonClick, noAutoPart, children} = props;

    const wrappedIcon = icon ? <div className={styles.infoPanelHeaderImage}>{icon}</div> : null;
    const wrappedChildren = noAutoPart === true
        ? children
        : <InfoPanel.Part>{children}</InfoPanel.Part>;

    return <Container className={styles.infoPanel}>
        <InfoPanel.Part innerClassName={styles.infoPanelTitleCol}>
            <div className={styles.infoPanelTitle}>
                <h1>{title}</h1>
            </div>

            <div className={styles.editButtonContainer}>
                {showEditButton === true &&
                    <EditButton title="Edit document"
                                onClick={() => onEditButtonClick!()}>
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </EditButton>}
            </div>

            {wrappedIcon}
        </InfoPanel.Part>

        {wrappedChildren}
    </Container>;
};

InfoPanel.Part = props => {
    return <Row className={styles.part}>
        <Col className={props.innerClassName}>
            {props.children}
        </Col>
    </Row>;
};

export default InfoPanel;

const EditButton = withStyles({
    root: {
        width: "1.5rem",
        minWidth: "0"
    }
})(Button);