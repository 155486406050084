import React, {ChangeEvent} from "react";
import styles from "./DucatsEditor.module.scss";
import {IEditorComponentProps} from "./common";
import {Col, Row} from "react-bootstrap";
import ducatsIcon from "../assets/ducats.png";
import {Spec} from "immutability-helper";
import {Input} from "@material-ui/core";

type InputChange = ChangeEvent<HTMLInputElement>;

const DucatsEditor: React.FC<IEditorComponentProps<number>> = props =>{
    const {value, onStateUpdate} = props;

    return <Row className="mb-1">
        <Col xs={9} sm={5} md={4} lg={2}>
            <span className="mr-3">
                <img src={ducatsIcon} title="Ducats" alt="ducats"/>
                &nbsp;
                Cost
            </span>
        </Col>

        <Col xs={3} sm={4} lg={2}>
            <Input className={styles.input}
                              value={value.toString()}
                              onChange={(ev: InputChange) =>
                                  inputChanged(ev.target.value, onStateUpdate)}/>
        </Col>
    </Row>;
};

export default DucatsEditor;

function inputChanged(input: string, onStateUpdate: (stateUpdate: Spec<number>) => void) {
    if (input === "" || isNaN(input as any)) return;

    const value = parseInt(input);
    if (value < 0) return;

    onStateUpdate({$set: value});
}