import React, {useState} from "react";
import styles from "./CookieNotice.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {lightGreen} from "@material-ui/core/colors";
import ColoredOutlineButton from "./ColoredOutlineButton";

const COOKIES_ACCEPTED_ITEM_NAME = "astrolabe_cookiesAccepted";

const CookieNotice: React.FC = () => {
    const [accepted, setAccepted] = useState<boolean>(
        (Boolean)(localStorage.getItem(COOKIES_ACCEPTED_ITEM_NAME))
    );

    if (accepted) return null;

    function acceptCookies() {
        localStorage.setItem(COOKIES_ACCEPTED_ITEM_NAME, "true");
        setAccepted(true);
    }

    const DismissButton = ColoredOutlineButton(lightGreen);

    return <div className={styles.container}>
        <Container>
            <Row>
                <Col>
                    <div className={styles.banner}>
                        <div className={styles.notice}>
                            This website uses Google Analytics cookies to provide better user experience and analyze site usage.
                            By continuing to use this website you consent to the use of cookies.
                        </div>

                        <div className={styles.button}>
                            <DismissButton onClick={acceptCookies}>
                                Dismiss
                            </DismissButton>
                        </div>
                    </div>

                </Col>
            </Row>
        </Container>
    </div>;
};

export default CookieNotice;