import React from "react";
import {PlayerClass} from "../model/common";

import advIcon from "../assets/job/adventure.gif";
import tradeIcon from "../assets/job/trade.gif";
import battleIcon from "../assets/job/battle.gif";

const JobImage: React.FC<{class: PlayerClass | string}> = props => {
    const icon = classToIcon(props.class);

    return <img src={icon}
                alt={props.class} />
};

function classToIcon(clazz: PlayerClass | string) {
    switch (clazz) {
        case PlayerClass.Adventure:
            return advIcon;
        case PlayerClass.Trade:
            return tradeIcon;
        case PlayerClass.Battle:
            return battleIcon;
    }
}

export default JobImage;