import React from "react";
import {Col, Row} from "react-bootstrap";
import News from "./news/News";

const Home: React.FC = () => {
    return <>
        <Row>
            <Col>
                <h1>Welcome to Astrolabe</h1>
            </Col>
        </Row>

        <Row>
            <Col>
                <p>Please select an area of the database from the navigation bar on the left or search for a specific
                    term using the search bar above.</p>
            </Col>
        </Row>

        {<News/>}
    </>;
};

export default Home;
