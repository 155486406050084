import WikiModel from "../model/wiki";
import BackendService from "./BackendService";

export interface IWikiService {
    getWikiPage(key: string): Promise<WikiModel>;

    updateWikiPage(key: string, title: string, content: string, idToken: string): Promise<string | undefined>;
}

class WikiService implements IWikiService {
    public getWikiPage(key: string): Promise<WikiModel> {
        return BackendService.get<WikiModel>(`/wiki/${key}`)
            .then(result => result.data);
    }

    public updateWikiPage(key: string, title: string, content: string, idToken: string): Promise<string | undefined> {
        return BackendService.post(`/wiki/${key}`, {title, content}, idToken)
            .then(response => response.location);
    }
}

export default (new WikiService());