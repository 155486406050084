import React from "react";
import remark from "remark";
import remarkParse, {Tokenizer} from "remark-parse";
import {generateKey} from "../utils";
import InlineLink from "./InlineLink";
import "./AstrolabeMarkdown.scss";
import styles from "./AstrolabeMarkdown.module.scss";
import {Link} from "react-router-dom";

const remark2rehype = require("remark-rehype");
const rehype2react = require("rehype-react");
const rehypeSanitize = require("rehype-sanitize");
const slug = require('rehype-slug');
const toc = require('remark-toc');
const remove = require('unist-util-remove');
const merge = require('deepmerge');
const gh = require('hast-util-sanitize/lib/github');


const AstrolabeMarkdown: React.FC<{source: string}> = props => {
    const {source} = props;

    const sanitizerOptions = merge(gh,{
        tagNames: ["InlineLink"],
        attributes: {"InlineLink": ["type", "subtype", "dKey", "name"]},
        clobberPrefix: ""
    });

    return <div className={styles.tableWrapper}>{
        remark()
            .use(toc)
            .use(remark2rehype, {
                handlers: {InlineLink: inlineLinkHandler}
            })
            .use(tableTextRemover)
            .use(slug)
            .use(tocWrapper)
            .use(rehypeSanitize, sanitizerOptions)
            .use(rehype2react, {
                createElement: React.createElement,
                components: {InlineLink: InlineLink, 'a': InternalLinkRouter}
            })
            .processSync(source)
            .contents
    }</div>;
};

export default AstrolabeMarkdown;

const tokenizeInlineLink = (eat: remarkParse.Eat, value: string) => {
    const match = /^\[\[([\w-]+):((\w+):)?(.+?)]]/.exec(value);

    if (match) {
        const type = match[1];
        const subtype = match[3];
        const name = match[4];

        return eat(match[0])({
            type: "InlineLink",
            properties: {
                type,
                subtype,
                dKey: generateKey(name),
                name
            }
        });
    }
};

tokenizeInlineLink.notInLink = true;
tokenizeInlineLink.locator = locateInlineLink;

function locateInlineLink(value: string, fromIndex: number) {
    return value.indexOf("[[", fromIndex);
}

function inlineLinkHandler(h: any, node: any) {
    return {
        type: 'element',
        tagName: 'InlineLink',
        properties: node.properties
    };
}

function tableTextRemover() {
    return transformer;

    function transformer(tree: any): any {
        remove(tree, (node: any, index: any, parent: any) => {
            return node.type === "text" && ["table", "thead", "tbody", "tr"].includes(parent.tagName);
        });
    }
}

function tocWrapper() {
    return transformer;

    function transformer(tree: any): any {
        const tocIdx = tree.children.findIndex(
            (n: any) => n.properties && n.properties.id === "table-of-contents"
        );

        if (tocIdx < 0) {
            return;
        }

        const tocWrapper = {
            type: "element",
            tagName: "div",
            properties: {
                id: "toc-wrapper"
            },
            children: tree.children.slice(tocIdx, tocIdx + 3),
        };

        tree.children = [...tree.children.slice(0, tocIdx), tocWrapper, ...tree.children.slice(tocIdx + 3)];
    }
}

const InternalLinkRouter: React.FC<{href: string}> = props => {
    const { href, children } = props;

    if (href.startsWith("/")) {
        return <Link to={href}>
            {children}
        </Link>;
    }

    return <a href={href}>
        {children}
    </a>;
};

const tokenizers = remarkParse.Parser.prototype.inlineTokenizers;
const methods = remarkParse.Parser.prototype.inlineMethods;

if (!tokenizers.inline_link) {
    tokenizers.inline_link = (tokenizeInlineLink as Tokenizer);
    methods.splice(methods.indexOf("text"), 0, "inline_link");
}
