import React from "react";
import styles from "./TooltipCommon.module.scss";
import {
    BaseSkillModel,
    isPlayerSkill,
    LanguageSkillModel,
    PlayerSkillModel,
    ShipSkillModel,
    SkillModel
} from "../../model/skill";
import SkillService from "../../services/SkillService";
import {Col} from "react-bootstrap";
import SkillLink from "../SkillLink";
import TooltipBase, {withObservableTarget} from "./TooltipBase";
import { isEmpty } from "lodash";
import {PlayerClass} from "../../model/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCrown} from "@fortawesome/pro-duotone-svg-icons";
import {canRender} from "../../hoc/CanRender";
import Level from "../Level";
import Ducats from "../Ducats";
import AideDuty from "../AideDuty";
import {IDataProps} from "../../hoc/DataLoader";

const SkillTooltip: React.FC<IDataProps<SkillModel>> = props => {
    const skill = props.data;

    const titleIcon = (skill as PlayerSkillModel).refinementPossible ?
        <FontAwesomeIcon icon={faCrown} className={styles.refinedSkill}/> :
        undefined;

    const {requiredSkills} = skill as PlayerSkillModel | ShipSkillModel;

    return <TooltipBase title={skill.name} isLoading={false} titleIcon={titleIcon}>
        <TooltipBase.ContentRow>
            {skill.description}
        </TooltipBase.ContentRow>

        {!isEmpty(requiredSkills) && <TooltipBase.ContentRow noCol>
            {requiredSkills.map(s => <Col key={s.skill.key} xs={4}>
                <SkillLink to={s} showName={false}/>
            </Col>)}
        </TooltipBase.ContentRow>}

        {skill.subtype === 'aide' && <TooltipBase.ContentRow>
            Aide duties: <AideDuty duty={skill.aideDuty}/>
        </TooltipBase.ContentRow>}

        <LevelsAndCostSection data={skill}/>
    </TooltipBase>;
};

const LevelsAndCostSection = canRender<BaseSkillModel, PlayerSkillModel | LanguageSkillModel>(
    props => {
        const skill = props.data;

        return <TooltipBase.ContentRow noCol>
            {skill.subtype !== 'language' && <>
                <Col xs={4}>
                    <Level clazz={PlayerClass.Adventure} level={skill.advReq}/>
                </Col>
                <Col xs={4}>
                    <Level clazz={PlayerClass.Trade} level={skill.tradeReq}/>
                </Col>
                <Col xs={4}>
                    <Level clazz={PlayerClass.Battle} level={skill.battleReq}/>
                </Col>
            </>}

            <Col>
                <Ducats amount={skill.cost}/>
            </Col>
        </TooltipBase.ContentRow>;
    },
    isPlayerSkill
);

export default withObservableTarget(
    SkillTooltip,
    props => SkillService.getSkillWithCache(props.subtype!, props.key)
);
