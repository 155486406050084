import React from "react";
import styles from "./TooltipCommon.module.scss";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {LinkableDocumentModel} from "../../model/document";
import {withObservableDataLoader, IDataProps} from "../../hoc/DataLoader";
import {Observable} from "rxjs";

interface ITooltipBaseProps {
    title: string;
    isLoading: boolean;
    titleIcon?: any;
}

interface IContentRowProps {
    noCol?: boolean;
}

type ITooltipBase = React.FC<ITooltipBaseProps> &
    {ContentRow: React.FC<IContentRowProps>};

const TooltipBase: ITooltipBase = props => {
    const {isLoading, title, titleIcon, children} = props;

    return <Container className={styles.tooltipContainer}>
        <Row className={styles.tooltipTitle} noGutters>
            <Col>
                {title}
                &nbsp;
                {isLoading && <FontAwesomeIcon icon={faSpinner} className="fa-pulse"/>}
                <div className={styles.tooltipTitleIcon}>
                    {titleIcon && titleIcon}
                </div>
            </Col>
        </Row>

        {children}
    </Container>;
};

TooltipBase.ContentRow = props => {
    const {noCol, children} = props;

    const Wrapper = (noCol === true) ? React.Fragment : Col;

    return <Row className={styles.tooltipRow} noGutters>
        <Wrapper>{children}</Wrapper>
    </Row>;
};

export default TooltipBase;

export interface ITargetProps<T> {
    target: T;
}

export function withObservableTarget<TProps extends LinkableDocumentModel, TData>(
    WrappedComponent: React.FC<IDataProps<TData>>,
    dataLoader: (props: TProps) => Observable<TData>) {
    return (props: ITargetProps<TProps>) => {
        const {target} = props;

        const WithLoader = withObservableDataLoader<TData>(
            WrappedComponent,
            () => dataLoader(target),
            () => false,
            () => <TooltipBase title={target.name} isLoading={true}/>
        );

        return <WithLoader/>
    };
}