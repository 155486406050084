import React, {useState} from "react";
import styles from "./JobSelector.module.scss";
import { Autocomplete } from '@material-ui/lab';
import {TextField} from "@material-ui/core";
import {LinkableDocumentModel} from "../model/document";
import SearchService from "../services/SearchService";

interface IJobSelectorProps {
    value: LinkableDocumentModel | undefined;
    onSelected: (job: LinkableDocumentModel | undefined) => void;
}

const JobSelector: React.FC<IJobSelectorProps> = props => {
    const {value, onSelected} = props;

    const [options, setOptions] = useState<LinkableDocumentModel[]>([]);

    function onInput(ev: any) {
        const newValue = ev.target.value as string;

        if (newValue.length >= 3) {
            SearchService.search(newValue, ["job"])
                .then(results => setOptions(results));
        }
    }

    return <Autocomplete value={value === undefined ? null : value}
                         classes={{ inputRoot: styles.input }}
                         options={options}
                         renderInput={params =>
                             <TextField {...params}/>}
                         renderOption={option => <>{option.name}</>}
                         getOptionLabel={option => option.name}
                         onInput={onInput}
                         onChange={(ev: any, value: LinkableDocumentModel | null) =>
                             onSelected(value === null ? undefined : value)}/>
};

export default JobSelector;