import React from "react";
import {ButtonGroup} from "@material-ui/core";
import {generateHexNonce, mapToQueryString} from "../utils";
import {AppState} from "../store/state";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignInAlt, faSignOut, faUserAlt} from "@fortawesome/pro-duotone-svg-icons";
import {useHistory} from "react-router-dom";
import {AnalyticsService} from "../services/AnalyticsService";
import {amber, cyan, lightGreen} from "@material-ui/core/colors";
import ColoredOutlineButton from "../fragments/ColoredOutlineButton";

type StateSelect = {
    isUserAuthenticated: boolean;
    displayName: string | undefined;
};

const UserInfo: React.FC = () => {
    const {isUserAuthenticated, displayName} = useSelector<AppState, StateSelect>(
        state => state !== undefined && state.userInfo !== undefined
            ? {isUserAuthenticated: true, displayName: state.userInfo.displayName}
            : {isUserAuthenticated: false, displayName: undefined});

    const history = useHistory();

    if (isUserAuthenticated) {
        const ProfileButton = ColoredOutlineButton(lightGreen);
        const LogoutButton = ColoredOutlineButton(amber);

        return <ButtonGroup>
            <ProfileButton onClick={() => history.push("/profile")}>
                <FontAwesomeIcon icon={faUserAlt} className="mr-2"/>
                {displayName}
            </ProfileButton>

            <LogoutButton onClick={handleLogoutClick}>
                Logout
                <FontAwesomeIcon icon={faSignOut} className="ml-2"/>
            </LogoutButton>
        </ButtonGroup>;
    } else {
        const LoginButton = ColoredOutlineButton(cyan);

        return <ButtonGroup>
            <LoginButton onClick={handleLoginClick}>
                <FontAwesomeIcon icon={faSignInAlt} className="mr-2"/>
                Login
            </LoginButton>
        </ButtonGroup>;
    }
};

function handleLoginClick() {
    AnalyticsService.loginClicked();

    const nonce = "astrolabe_" + generateHexNonce();

    localStorage.setItem(nonce, JSON.stringify({
        returnUrl: window.location.pathname
    }));

    window.location.assign(constructLoginUrl(nonce));
}

function handleLogoutClick() {
    window.location.assign(constructLogoutUrl());
}

function constructLoginUrl(state: string): string {
    const params = {
        client_id: process.env.REACT_APP_ASTROLABE_CLIENT_ID,
        response_type: "code",
        scope: "email openid profile aws.cognito.signin.user.admin",
        redirect_uri: `${window.location.origin}/auth_callback`,
        state: state
    };

    return `${process.env.REACT_APP_ASTROLABE_AUTH_URL_BASE}/login?${mapToQueryString(params)}`;
}

function constructLogoutUrl(): string {
    const params = {
        client_id: process.env.REACT_APP_ASTROLABE_CLIENT_ID,
        logout_uri: `${window.location.origin}/logout_callback`
    };

    return `${process.env.REACT_APP_ASTROLABE_AUTH_URL_BASE}/logout?${mapToQueryString(params)}`;
}

export default UserInfo;