import BackendService from "./BackendService";
import {JobModel} from "../model/job";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

const JobService = {
    getJob(key: string): Promise<JobModel> {
        return BackendService.get<JobModel>(`/job/${key}`)
            .then(response => response.data);
    },

    getJobWithCache(key: string): Observable<JobModel> {
        return BackendService.getObservableWithCache<JobModel>(`/job/${key}`)
            .pipe(map(response => response.data));
    },

    getJobs(subtype?: string, skills?: string[]): Promise<JobModel[]> {
        let url = '/jobs';

        if (subtype) {
            url += '/' + subtype;
        }

        if (skills) {
            url += '?skills=' + skills.join(',');
        }

        return BackendService.get<JobModel[]>(url)
            .then(response => response.data);
    }
};

export default JobService;