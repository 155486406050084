import {MetadataModel, QuestBrowserMetadataModel} from "../model/metadata";
import BackendService from "./BackendService";

interface IMetadataService {
    getQuestBrowserMetadata(): Promise<QuestBrowserMetadataModel>
}

class MetadataService implements IMetadataService {
    public getQuestBrowserMetadata(): Promise<QuestBrowserMetadataModel> {
        return this.getMetadata("quest-browser");
    }

    private getMetadata<T>(key: string): Promise<QuestBrowserMetadataModel> {
        return BackendService.get<MetadataModel<QuestBrowserMetadataModel>>(`/metadata/${key}`)
            .then(meta => meta.data.data);
    }
}

export default new MetadataService();