import React from "react";
import {INanbanRates, TradeGoodModel} from "../../model/trade-good";
import TradeGoodService from "../../services/TradeGoodService";
import PageHeaders from "../../widgets/PageHeaders";
import InfoPanel from "../../fragments/InfoPanel";
import {Col, Row} from "react-bootstrap";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {faBoxesAlt, faExchange, faGlobeAmericas, faInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import UnderConstruction from "../../fragments/UnderConstruction";
import RateBar from "../../fragments/RateBar";
import {IDataProps, withObservableDataLoader} from "../../hoc/DataLoader";
import { isEmpty } from "lodash";

const TradeGoodViewer: React.FC<IDataProps<TradeGoodModel>> = props => {
    const tradeGood = props.data;
    if (!tradeGood) return null;

    const icon = <img src={`https://assets.astrolabe.fyi/icons/trade-good/${tradeGood.key}.gif`}
                      alt={tradeGood.key}/>;

    return <>
        <PageHeaders subject={tradeGood}/>

        <UnderConstruction>
            The trade goods section of Astrolabe is currently under construction.
            Price information is missing, collection/procurement information is missing.
        </UnderConstruction>

        <InfoPanel title={tradeGood.name} icon={icon}>
            <Row>
                <Col md={7}>
                    <InfoPanelSection icon={faInfoCircle} text="Description">
                        {tradeGood.description}
                    </InfoPanelSection>

                    {renderNanbanRatesSection(tradeGood.nanbanRates)}
                </Col>

                <Col md={5} className="mt-3 mt-md-0">
                    <InfoPanelSection icon={faBoxesAlt} text="Category">
                        {tradeGood.category}
                    </InfoPanelSection>

                    {tradeGood.localSpecialty && <>
                        <InfoPanelSection icon={faGlobeAmericas} text="Local specialty">
                            {tradeGood.localSpecialty}
                        </InfoPanelSection>
                    </>}
                </Col>
            </Row>

        </InfoPanel>
    </>;
};

function renderNanbanRatesSection(rates: INanbanRates) {
    if (isEmpty(rates)) {
        return null;
    }

    return <InfoPanelSection icon={faExchange} text="Nanban exchange rates">
        <Row>
            <Col xs={3}>
                {renderSingleNanbanRate("Korea", rates.korea)}
            </Col>
            <Col xs={3}>
                {renderSingleNanbanRate("Japan", rates.japan)}
            </Col>
            <Col xs={3}>
                {renderSingleNanbanRate("Taiwan", rates.taiwan)}
            </Col>
            <Col xs={3}>
                {renderSingleNanbanRate("China", rates.china)}
            </Col>
        </Row>
    </InfoPanelSection>;
}

function renderSingleNanbanRate(name: string, rate?: number): any {
    if (rate === undefined) return null;

    return <>
        {name} <br/>
        <RateBar value={rate} color={rateToColor(rate)}/>
    </>;
}

function rateToColor(rate: number): string {
    //TODO: Linear color scaling?
    if (rate < 35) return "#ea4629";
    if (rate < 70) return "#e9e63f";
    if (rate < 100) return "#40e91d";
    return "#2ee9e8";
}

export default withObservableDataLoader<TradeGoodModel, {key: string}>(
    TradeGoodViewer,
    props => TradeGoodService.getTradeGoodWithCache(props.match.params.key),
    (props, prevProps) => props.match.params.key !== prevProps.match.params.key
);
