import React, {useEffect, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {ShipModel} from "../../model/ship";
import Loading from "../../fragments/Loading";
import {Col, Row} from "react-bootstrap";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {faServicestack} from "@fortawesome/free-brands-svg-icons";
import styles from "./ShipBrowser.module.scss";
import ShipService from "../../services/ShipService";
import {navigate, upperCaseFirst} from "../../utils";
import PageHeaders from "../../widgets/PageHeaders";
import UnderConstruction from "../../fragments/UnderConstruction";
import InfoPanel from "../../fragments/InfoPanel";
import ShipLine from "../../fragments/lines/ShipLine";
import {BaseSkillModel} from "../../model/skill";
import MultiSkillSelector from "../../widgets/MultiSkillSelector";
import { ButtonGroup } from "@material-ui/core";
import SmartButton from "../../widgets/SmartButton";

interface IShipBrowserMatch {
    subtype: string | undefined;
}

interface IButtonDef {
    subtype: string | undefined;
    label: string;
    baseColor: string;
}

const TYPE_BUTTON_DEFS: IButtonDef[] = [
    {
        subtype: undefined,
        label: "All",
        baseColor: "#888888"
    },
    {
        subtype: "adventure",
        label: "Adventure",
        baseColor: "#338833"
    },
    {
        subtype: "trade",
        label: "Trade",
        baseColor: "#999933"
    },
    {
        subtype: "battle",
        label: "Battle",
        baseColor: "#AA3333"
    }
];

const ShipBrowser: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [skillsSelected, setSkillsSelected] = useState<BaseSkillModel[]>([]);
    const [ships, setShips] = useState<ShipModel[]>([]);

    const history = useHistory();
    const match = useRouteMatch<IShipBrowserMatch>();

    const {subtype} = match.params;

    function renderHeaders() {
        if (subtype === undefined) {
            return <PageHeaders title="Ships"/>;
        } else {
            return <PageHeaders title={`${upperCaseFirst(subtype)} ships`}/>;
        }
    }

    function isSelectionValid(): boolean {
        let numSelected: number = 0;

        if (subtype) numSelected++;

        if (skillsSelected.length > 0) numSelected++;

        return numSelected >= 1;
    }

    function loadShips(): void {
        if (!isSelectionValid()) {
            setShips([]);
            return;
        }

        const skillKeys = skillsSelected.map(skill => skill.key);

        setIsLoading(true);
        ShipService.getShips(subtype, skillKeys)
            .then(ships => {
                setShips(ships);
                setIsLoading(false);
            });
    }

    useEffect(loadShips, [subtype, skillsSelected]);

    return <>
        {renderHeaders()}

        <UnderConstruction>
            Ship section of Astrolabe is currently under development.
            Premium ships are missing, some stats are not displayed, skill recipes are missing.
        </UnderConstruction>

        <InfoPanel title="Ship browser">
            <InfoPanelSection icon={faServicestack} text="Ship type">
                <ButtonGroup>
                    {TYPE_BUTTON_DEFS.map(btn =>
                        <SmartButton key={btn.label}
                                     baseColor={btn.baseColor}
                                     isSelected={btn.subtype === subtype}
                                     onClick={() => navigate(history, "ships", btn.subtype)}
                                     className={styles.typeBtn}>
                            {btn.label}
                        </SmartButton>)}
                </ButtonGroup>

                <Row className="mt-1">
                    <Col>
                        <MultiSkillSelector placeholder="Filter by optional skills"
                                            subtypes={["ship"]}
                                            selectedSkills={skillsSelected}
                                            onSelectionChanged={setSkillsSelected}/>
                    </Col>
                </Row>
            </InfoPanelSection>
        </InfoPanel>

        {isLoading && <Loading/>}

        {!isLoading && <>
            {ships.map(ship => <ShipLine key={ship.key}
                                         ship={ship}
                                         hideIcon/>)}

            {!isSelectionValid() &&
            <div className={styles.resultsInformation}>
                At least one selection is required to perform a search.
            </div>}

            {isSelectionValid() && ships.length === 0 &&
            <div className={styles.resultsInformation}>
                No ships found matching the selected criteria.
            </div>}
        </>}
    </>;
};

export default ShipBrowser;
