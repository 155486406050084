import BackendService from "./BackendService";

export interface IUserProfileModifiable {
    displayName: string;
}

export interface IUserProfile extends IUserProfileModifiable {
    email: string;
}

export interface IUserProfileService {
    getUserProfile(accessToken: string): Promise<IUserProfile>;

    modifyUserProfile(changes: IUserProfileModifiable, accessToken: string): Promise<IUserProfile>;
}

class UserProfileService implements IUserProfileService {
    public getUserProfile(accessToken: string): Promise<IUserProfile> {
        return BackendService.get<IUserProfile>("/profile", accessToken)
            .then(response => response.data);
    }

    public modifyUserProfile(changes: IUserProfileModifiable, accessToken: string): Promise<IUserProfile> {
        return BackendService.patch<IUserProfile>("/profile", changes, accessToken)
            .then(response => response.data);
    }
}

export default (new UserProfileService());