import React, {useState} from "react";
import styles from "./SkillSelector.module.scss";
import commonStyles from "../styles/common.module.scss";
import SearchService from "../services/SearchService";
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import {BaseSkillModel} from "../model/skill";
import {Autocomplete} from "@material-ui/lab";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AssetImage from "../fragments/AssetImage";

interface ISkillSelectorProps {
    value: BaseSkillModel | null;
    placeholder?: string;
    subtypes: string[];
    onChange: (skill: BaseSkillModel | null) => void;
}

const SkillSelector: React.FC<ISkillSelectorProps> = props => {
    const {value, placeholder, subtypes, onChange} = props;

    const [options, setOptions] = useState<BaseSkillModel[]>([]);

    if (value !== null) {
        return <TextField disabled
                          value={value.name}
                          InputProps={{
                              startAdornment:
                                  <InputAdornment position="start">
                                      <AssetImage item={value}/>
                                  </InputAdornment>,
                              endAdornment:
                                  <InputAdornment position="end">
                                      <IconButton size="small"
                                                  className={styles.remove}
                                                  onClick={() => onChange(null)}>
                                          <FontAwesomeIcon icon={faTimes}/>
                                      </IconButton>
                                  </InputAdornment>
                          }}
                          classes={{root: styles.input}}
                          />;
    }

    return <>
        <Autocomplete value={value}
                      options={options}
                      renderInput={params =>
                          <TextField className={commonStyles.fullWidth}
                                     placeholder={placeholder}
                                     {...params}/>}
                      getOptionLabel={skill => skill.name}
                      renderOption={skill => <div className={styles.option}>
                          <AssetImage item={skill}/>
                          &nbsp;
                          {skill.name}
                      </div>}
                      onInputChange={(ev: any, input: string) => {
                          if (input.length >= 3) {
                              getSkills(input, subtypes, setOptions);
                          } else {
                              setOptions([]);
                          }
                      }}
                      onChange={(ev: any, skill: BaseSkillModel | null) => onChange(skill)}
                      />
    </>;
};

function getSkills(term: string,
                   subtypes: string[],
                   callback: (skills: BaseSkillModel[]) => void) {
    SearchService.search(term, ["skill"], subtypes)
        .then(skills => callback(skills as BaseSkillModel[]));
}

export default SkillSelector;
