import React from "react";
import {PlayerClass} from "../model/common";
import {upperCaseFirst} from "../utils";

interface IPlayerClassIconProps {
    pClass: PlayerClass | string;
    alt?: string;
    title?: string;
}

const PlayerClassIcon: React.FC<IPlayerClassIconProps> = props => {
    const alt = props.alt || props.pClass;
    const title = props.title || upperCaseFirst(props.pClass);

    return <img src={require(`../assets/${props.pClass}.png`)}
                alt={alt}
                title={title}/>
};

export default PlayerClassIcon;