import React, {ReactNode} from "react";
import styles from "./RecipeBookViewer.module.scss";
import {IDataProps, withObservableDataLoader} from "../../hoc/DataLoader";
import {RecipeBookModel} from "../../model/recipe-book";
import RecipeService from "../../services/RecipeService";
import {PageHeaders} from "../../widgets";
import InfoPanel from "../../fragments/InfoPanel";
import {Col, Row} from "react-bootstrap";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {faInfoCircle, faMapMarkerQuestion} from "@fortawesome/pro-regular-svg-icons";
import SkillLink from "../../fragments/SkillLink";
import UnderConstruction from "../../fragments/UnderConstruction";
import {ItemAmountModel} from "../../model/item";
import InlineLink from "../../fragments/InlineLink";
import AssetImage from "../../fragments/AssetImage";
import {FixedAmountModel, ProductAmountModel, VariableAmountModel} from "../../model/recipe";
import {isNumeric} from "rxjs/internal-compatibility";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faBadgeCheck, faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {faRectanglePortrait as faRectanglePortraitFull} from "@fortawesome/pro-duotone-svg-icons";
import {faRectanglePortrait as faRectanglePortraitEmpty} from "@fortawesome/pro-regular-svg-icons";

const RecipeBookViewer : React.FC<IDataProps<RecipeBookModel>> = props => {
    const recipeBook = props.data;
    if (!recipeBook) return null;

    const icon = <AssetImage item={{
        type: "recipe-book",
        subtype: undefined,
        key: recipeBook.subtype!
    }} extension="png"/>;

    return <>
        <PageHeaders subject={recipeBook}/>

        <UnderConstruction>
            Recipe view is currently a work in progress.
        </UnderConstruction>

        <InfoPanel title={recipeBook.name} icon={icon}>
            <InfoPanel.Part>
                <Row>
                    <Col md={6}>
                        <InfoPanelSection icon={faInfoCircle} text="Description">
                            {recipeBook.description}
                        </InfoPanelSection>
                    </Col>

                    <Col md={6}>
                        <InfoPanelSection icon={faMapMarkerQuestion} text="Source">
                            {recipeBook.source}
                        </InfoPanelSection>
                    </Col>
                </Row>
            </InfoPanel.Part>

            {recipeBook.recipes.map((recipe, idx) => <InfoPanel.Part key={idx}>
                <Row>
                    <Col xs={4} md={3} lg={2}>
                        {recipe.skills.map(skill =>
                            <SkillLink key={skill.skill.key}
                                       to={skill}
                                       showName={false}/>)}
                    </Col>

                    <Col xs={8} md={9} lg={10}>
                        {recipe.name}
                        <div className={styles.recipeDescription}>
                            {recipe.description}
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className={styles.recipeBox}>
                        <div className={styles.ingredientsBox}>
                            {recipe.ingredients.map(item => <ItemBlock key={item.item.key}
                                                                       target={item}/>)}
                        </div>

                        <div className={styles.arrowBoxRight}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                        </div>

                        <div className={styles.arrowBoxDown}>
                            <FontAwesomeIcon icon={faChevronDown}/>
                        </div>

                        <div className={styles.productBox}>
                            {recipe.products!.map(item => <ItemBlock key={item.item.key}
                                                                     target={item}/>)}
                        </div>
                    </Col>
                </Row>
            </InfoPanel.Part>)}
        </InfoPanel>
    </>;
}

const ItemBlock : React.FC<{target: ItemAmountModel | ProductAmountModel}> = props => {
    const {item, amount} = props.target;

    return <div className={styles.itemBox}>
        <div className={styles.itemIcon} title={item.name}>
            <AssetImage item={item}/>

            <div className={styles.count}>
                {isNumeric(amount) ? amount : displayAmount(amount)}
            </div>
        </div>

        <div className={styles.itemName}>
            <InlineLink to={item} hideIcon/>
        </div>

        {!isNumeric(amount) && amount.condition && <div className={styles.diceBox}>
            {displayCondition(amount.condition)}
        </div>}
    </div>;
}

function displayAmount(amount: FixedAmountModel | VariableAmountModel): string {
    if (amount.type === 'fixed') {
        return amount.count.toString();
    } else {
        return `${amount.min}-${amount.max}`;
    }
}

function displayCondition(condition: string): ReactNode {
    switch (condition) {
        case "Great Success":
            return <FontAwesomeIcon icon={faBadgeCheck}
                                    color="gold"
                                    title="Great Success"/>;
        case "Very high probability":
            return dice(5, condition);
        case "High probability":
            return dice(4, condition);
        case "Low probability":
            return dice(2, condition);
        case "Very low probability":
            return dice(1, condition);
        default:
            return condition;
    }
}

function probToColor(prob: number) {
    switch (prob) {
        case 1: return "#ea4629";
        case 2: return "#ea9029";
        case 3: return "#e9e63f";
        case 4: return "#40e91d";
        case 5: return "#2ee9e8";
    }
}

function dice(count: number, title: string): ReactNode {
    return <div title={title}>
        {[...Array(count)].map((i, ii) =>
            <FontAwesomeIcon key={ii}
                             icon={faRectanglePortraitFull}
                             color={probToColor(count)}
                             className={styles.dice}/>)}
        {[...Array(5 - count)].map((i, ii) =>
            <FontAwesomeIcon key={ii}
                             color="#222222"
                             icon={faRectanglePortraitEmpty}
                             className={styles.dice}/>)}
    </div>;
}

export default withObservableDataLoader<RecipeBookModel, {key: string}>(
    RecipeBookViewer,
    props => RecipeService.getRecipeBookWithCache(props.match.params.key),
    (props, prevProps) =>
        props.match.params.key !== prevProps.match.params.key
);
