import React, {ChangeEvent, useEffect, useState} from "react";
import styles from "./UserProfileEditor.module.scss";
import commonStyles from "../styles/common.module.scss";
import {Col, Row} from "react-bootstrap";
import UserProfileService, {IUserProfile} from "../services/UserProfileService";
import Loading from "../fragments/Loading";
import {AppState} from "../store/state";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import AuthService from "../services/AuthService";
import PageHeaders from "../widgets/PageHeaders";
import InfoPanel from "../fragments/InfoPanel";
import {Button, TextField} from "@material-ui/core";

const UserProfileEditor: React.FC = () => {
    const [userProfile, setUserProfile] = useState<IUserProfile | undefined>(undefined);
    const [isSubmittingChanges, setIsSubmittingChanges] = useState<boolean>(false);

    const accessToken = useSelector<AppState, string | undefined>(
        state => state && state.userInfo
            ? state.userInfo.accessToken
            : undefined);

    const dispatch = useDispatch();

    function handleDisplayNameChange(event: ChangeEvent<HTMLInputElement>) {
        setUserProfile({
            email: userProfile!.email,
            displayName: event.target.value
        });
    }

    function handleSubmitClick() {
        setIsSubmittingChanges(true);
        UserProfileService.modifyUserProfile({
            displayName: userProfile!.displayName
        }, accessToken!)
            .then(userProfile => {
                setIsSubmittingChanges(false);
                setUserProfile(userProfile);

                AuthService.tryRefreshAuthAndDispatch(dispatch).then();
            });
    }

    function tryLoadProfile() {
        if (accessToken !== undefined) {
            UserProfileService.getUserProfile(accessToken)
                .then(setUserProfile);
        } else {
            setUserProfile(undefined);
        }
    }

    useEffect(tryLoadProfile, [accessToken]);

    if (userProfile === undefined) {
        return <Loading/>;
    }

    return <>
        <PageHeaders title="User profile"/>

        <InfoPanel title={"Profile of " + userProfile.displayName}>
            <Row className="mt-3 mb-1">
                <Col sm={8} md={6} lg={4}>
                    <TextField label="E-mail"
                               value={userProfile.email}
                               disabled
                               className={commonStyles.fullWidth}/>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col className={styles.additionalInfo}>
                    This is the e-mail you have signed-up with on this site (directly or though third-party identity providers)
                    and cannot be changed.
                    This will <b>not</b> be displayed to other users and only site administrators will have access to it.
                </Col>
            </Row>

            <Row className="mt-3 mb-1">
                <Col sm={8} md={6} lg={4}>
                    <TextField label="Display name"
                               value={userProfile.displayName}
                               onChange={handleDisplayNameChange}
                               className={commonStyles.fullWidth}/>
                </Col>
            </Row>

            <Row className="mb-3">
                <Col className={styles.additionalInfo}>
                    This is the name that will be displayed to other users of the website alongside your contributions.
                    Accounts with abusive or inflammatory display names may be subject to deletion at the site administrators' discretion.
                </Col>
            </Row>

            <Row>
                <Col className="text-right">
                    {!isSubmittingChanges &&
                    <Button variant="outlined"
                            onClick={handleSubmitClick}>
                        Submit
                    </Button>}

                    {isSubmittingChanges &&
                    <Button variant="outlined">
                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse"/>
                        &nbsp;
                        Submitting...
                    </Button>}
                </Col>
            </Row>
        </InfoPanel>
    </>;
};

export default UserProfileEditor;