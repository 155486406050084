import React from "react";
import styles from "./SkillLink.module.scss";
import {Link} from "react-router-dom";
import {BaseSkillModel, SkillRankModel} from "../model/skill";
import InlineLink from "./InlineLink";
import Tooltip from "./tooltips/Tooltip";
import AssetImage from "./AssetImage";

interface ISkillLinkProps {
    to: SkillRankModel | BaseSkillModel;
    showName: boolean;
    suppressTooltip?: boolean;
}

const SkillLink = (props: ISkillLinkProps) => {
    const normalizedProps = normalizeProps(props.to);
    const skill = normalizedProps.skill;
    const rank = normalizedProps.rank;

    let skillImage =
        <Link to={`/skill/${skill.subtype}/${skill.key}`}>
            <AssetImage item={skill}/>
            &nbsp;
        </Link>;

    if (!props.showName) {
        skillImage = <Tooltip target={skill}>
            {skillImage}
        </Tooltip>;
    }

    return <div className={styles.skillRank}>
        {skillImage}
        {rank && <span>{rank}</span>}
        {props.showName && <InlineLink to={skill}
                                       hideIcon
                                       suppressTooltip={props.suppressTooltip}/>}
    </div>
};

SkillLink.defaultProps = {
    subtype: "player",
    rank: undefined,
    showName: true
};

function normalizeProps(to: SkillRankModel | BaseSkillModel): SkillRankModel {
    if ((to as SkillRankModel) && (to as SkillRankModel).skill) {
        return to as SkillRankModel;
    } else {
        return {
            skill: to as BaseSkillModel,
            rank: null
        }
    }
}

export default SkillLink;
