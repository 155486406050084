export enum AideDuty {
    Navigator = "Navigator",
    Lookout = "Lookout",
    Paymaster = "Paymaster",
    StoreKeeper = "Store Keeper",
    Lieutenant = "Lieutenant",
    Surgeon = "Surgeon"
}

export const AllAideDuties = [
    AideDuty.Navigator,
    AideDuty.Lookout,
    AideDuty.Paymaster,
    AideDuty.StoreKeeper,
    AideDuty.Lieutenant,
    AideDuty.Surgeon
];