import {Action} from "./actions";
import initialState, {AppState} from "./state";

export default function rootReducer(
    state: AppState = initialState,
    action: Action
): AppState {
    switch(action.type) {
        case "AUTHENTICATE_USER":
            return {
                userInfo: action.userInfo
            };
    }
}