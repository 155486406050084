import React, {ChangeEvent} from "react";
import {IEditorComponentProps} from "./common";
import {PersonLocationModel} from "../model/common";
import {Col, Row} from "react-bootstrap";
import {Input} from "@material-ui/core";

const PersonLocationEditor: React.FC<IEditorComponentProps<PersonLocationModel>> = props => {
    const personLocation = props.value;
    const {onStateUpdate} = props;

    return <Row>
        <Col>
            <Input value={personLocation.location}
                   className="mr-2"
                   placeholder="Location"
                   onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                       onStateUpdate({location: {$set: ev.target.value}})}/>

            <Input value={personLocation.person}
                   placeholder="Person"
                   onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                       onStateUpdate({person: {$set: ev.target.value}})}/>
        </Col>
    </Row>;
};

export default PersonLocationEditor;