import React from "react";
import QuestService from "../../services/QuestService";
import {QuestModel} from "../../model/quest";
import {Col, Container, Row} from "react-bootstrap";
import styles from "./QuestViewer.module.scss";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {
    faArrowRight,
    faChevronCircleLeft,
    faHandHoldingBox,
    faHourglassHalf,
    faMapMarked,
    faSearch
} from "@fortawesome/pro-solid-svg-icons";
import {PersonLocationModel} from "../../model/common";
import {
    faCheckSquare,
    faHandRock,
    faHandshake,
    faInfoCircle,
    faInfoSquare,
    faLink,
    faLockOpen,
    faMap
} from "@fortawesome/pro-regular-svg-icons";
import AstrolabeMarkdown from "../../fragments/AstrolabeMarkdown";
import InlineLink from "../../fragments/InlineLink";
import {LinkableDocumentModel} from "../../model/document";
import {SkillRankModel} from "../../model/skill";
import StarDifficulty from "../../fragments/StarDifficulty";
import SkillLink from "../../fragments/SkillLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ItemAmountModel} from "../../model/item";
import PageHeaders from "../../widgets/PageHeaders";
import UnderConstruction from "../../fragments/UnderConstruction";
import InfoPanel from "../../fragments/InfoPanel";
import {formatDucats} from "../../utils";
import QuestLine from "../../fragments/lines/QuestLine";
import {IDataProps, withObservableDataLoader} from "../../hoc/DataLoader";

const QuestViewer: React.FC<IDataProps<QuestModel[]>> = props => {
    const quest = props.data[0];
    if (!quest) return null;

    return <>
        <PageHeaders subject={quest}/>

        <UnderConstruction>
            Quest section of Astrolabe is currently under development and is only available for preview.
            Only a minor portion of quests are available, information may be missing or inaccurate, links to discoveries are dead.
        </UnderConstruction>

        <InfoPanel title={quest.name}>
            <Row>
                {/* Right column - quest givers and details */}
                <Col md={7}>
                    {renderQuestGiversSection(quest.contractGivers)}

                    {quest.targetLocation && renderTargetLocationSection(quest.targetLocation)}

                    {renderDetails(quest.details)}

                    {quest.requiredItems && renderRequiredItems(quest.requiredItems)}
                </Col>

                {/* Right column - reward and requirements */}
                <Col md={5} className="mt-3 mt-md-0">
                    {renderRewardSection(quest)}

                    {quest.deadline && renderDeadlineSection(quest.deadline)}

                    {quest.discovery && renderDiscoverySection(quest.discovery)}

                    {quest.difficulty && renderDifficultySection(quest.difficulty)}

                    {quest.requiredSkills && renderRequirementsSection(quest.requiredSkills)}
                </Col>
            </Row>

            {quest.guide && renderGuide(quest.guide)}

            {quest.prerequisites && renderPrerequisitesSection(quest.prerequisites)}

            {quest.questChain && renderQuestChainSection(quest.questChain, quest.key)}

            {quest.unlockedQuests && renderUnlockedQuestsSection(quest.unlockedQuests)}
        </InfoPanel>
    </>;
};

function renderQuestGiversSection(questGivers: PersonLocationModel[]) {
    return <InfoPanelSection icon={faMap} text="Contract Location">
        {questGivers && questGivers.map((questGiver, i) => <Row key={i}>
            <Col>
                {questGiver.location} &mdash; {questGiver.person}
            </Col>
        </Row>)}

        {(!questGivers || questGivers.length === 0) && <Row>
            <Col>
                Unknown
            </Col>
        </Row>}
    </InfoPanelSection>;
}

function renderTargetLocationSection(location: LinkableDocumentModel) {
    return <InfoPanelSection icon={faMapMarked} text="Target location">
        <InlineLink to={location}/>
    </InfoPanelSection>;
}

function renderDetails(details: string) {
    return <InfoPanelSection icon={faInfoCircle} text="Quest Details">
        {details || "Not provided"}
    </InfoPanelSection>;
}

function renderRequiredItems(items: ItemAmountModel[]) {
    return <InfoPanelSection icon={faHandHoldingBox} text="Required items">
        {items.map(item => <Row key={item.item.key}>
            <Col>
                <InlineLink to={item.item}/> x{item.amount}
            </Col>
        </Row>)}
    </InfoPanelSection>;
}

function renderRewardSection(quest: QuestModel) {
    return <InfoPanelSection icon={faHandshake} text="Reward">
        <Row>
            <Col xs={6}>Completion</Col>
            <Col xs={6} sm={4} md={6} lg={4} xl={3} className="text-right">
                {quest.reward ? formatDucats(quest.reward) : "-"}
            </Col>
        </Row>

        <Row>
            <Col xs={6}>Advance</Col>
            <Col xs={6} sm={4} md={6} lg={4} xl={3} className="text-right">
                {quest.advance ? formatDucats(quest.advance) : "-"}
            </Col>
        </Row>

        {quest.rewards && quest.rewards.map(i => <Row key={i.item.key}>
            <Col>
                <InlineLink to={i.item}/>
                {i.amount && <span>&nbsp;x{i.amount}</span>}
            </Col>
        </Row>)}
    </InfoPanelSection>;
}

function renderDeadlineSection(deadline: number) {
    return <InfoPanelSection icon={faHourglassHalf} text="Deadline">
        {deadline} days
    </InfoPanelSection>;
}

function renderDiscoverySection(discovery: LinkableDocumentModel) {
    return <InfoPanelSection icon={faSearch} text="Discovery">
        <InlineLink to={discovery}/>
    </InfoPanelSection>;
}

function renderDifficultySection(difficulty: number) {
    return <InfoPanelSection icon={faHandRock} text="Difficulty">
        <StarDifficulty difficulty={difficulty}/>
    </InfoPanelSection>;
}

function renderRequirementsSection(skills: SkillRankModel[]) {
    return <InfoPanelSection icon={faCheckSquare} text="Required skills">
        <Row noGutters>
            {skills.map(s => <Col key={s.skill.key} xs={3} sm={2} md={3} lg={2}>
                <SkillLink to={s} showName={false}/>
            </Col>)}

            {skills.length === 0 && "None"}
        </Row>
    </InfoPanelSection>;
}

function renderPrerequisitesSection(prerequisites: (QuestModel | LinkableDocumentModel)[]) {
    return <InfoPanelSection icon={faChevronCircleLeft} text="Prerequisites">
        <Container>
            {prerequisites.map(pre => {
                if (pre.type === 'discovery') {
                    return <Row key={pre.key}>
                        <Col>
                            <InlineLink to={pre}/>
                        </Col>
                    </Row>;
                } else if (pre.type === 'quest') {
                    return <QuestLine key={pre.key}
                                      quest={pre as QuestModel}
                                      noSeparator/>;
                } else {
                    return null;
                }
            })}
        </Container>
    </InfoPanelSection>;
}

function renderQuestChainSection(chain: QuestModel[], currentQuestKey: string) {
    return <InfoPanelSection icon={faLink} text="Quest chain">
        <Container>
            {chain.map(quest => <React.Fragment key={quest.key}>
                {quest.key === currentQuestKey && <div className={styles.currentQuestArrowBox}>
                    <FontAwesomeIcon icon={faArrowRight}/>
                </div>}
                {<QuestLine quest={quest}/>}
            </React.Fragment>)}
        </Container>
    </InfoPanelSection>;
}

function renderUnlockedQuestsSection(quests: QuestModel[]) {
    return <InfoPanelSection icon={faLockOpen} text="Unlocks">
        <Container>
            {quests.map(quest => <QuestLine key={quest.key}
                                            quest={quest}/>)}
        </Container>
    </InfoPanelSection>;
}

function renderGuide(guide: string) {
    return <InfoPanelSection icon={faInfoSquare} text="Quest guide">
        <AstrolabeMarkdown source={guide}/>
    </InfoPanelSection>;
}

export default withObservableDataLoader<QuestModel[], {key: string}>(
    QuestViewer,
    props => QuestService.getQuestWithCache(props.match.params.key),
    (props, prevProps) => props.match.params.key !== prevProps.match.params.key
);
