import React, {useEffect, useState} from "react";
import {Redirect} from "react-router";
import {useDispatch} from "react-redux";
import {AUTHENTICATE_USER} from "../store/actions";
import Loading from "../fragments/Loading";

const LogoutCallback: React.FC = () => {
    const [redirectTarget, setRedirectTarget] = useState<string | undefined>();

    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.removeItem("astrolabe_refreshToken");

        dispatch({
            type: AUTHENTICATE_USER,
            userInfo: undefined
        });

        setRedirectTarget("/");
    }, [dispatch]);

    if (redirectTarget !== undefined) {
        return <Redirect to={redirectTarget!}/>;
    } else {
        return <Loading/>;
    }
};

export default LogoutCallback;