import React from "react";
import {Col, Row} from "react-bootstrap";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {faBadgeCheck, faCheckSquare, faInfoCircle, faShieldAlt, faTachometer} from "@fortawesome/pro-regular-svg-icons";
import {ShipModel, ShipSkillModel} from "../../model/ship";
import SkillLink from "../../fragments/SkillLink";
import ShipStatIcon, {ShipStatType} from "../../fragments/ShipStatIcon";
import {faCubes} from "@fortawesome/pro-solid-svg-icons";
import PlayerClassIcon from "../../fragments/PlayerClassIcon";
import ShipService from "../../services/ShipService";
import PageHeaders from "../../widgets/PageHeaders";
import UnderConstruction from "../../fragments/UnderConstruction";
import InfoPanel from "../../fragments/InfoPanel";
import {PlayerClass} from "../../model/common";
import {IDataProps, withObservableDataLoader} from "../../hoc/DataLoader";

const ShipViewer: React.FC<IDataProps<ShipModel>> = props => {
    const ship = props.data;
    if (!ship) return null;

    const icon = <img src={`https://assets.astrolabe.fyi/icons/ship/${ship.key}.gif`}
                      title={ship.name}
                      alt={ship.key}/>;

    return <>
        <PageHeaders subject={ship}/>

        <UnderConstruction>
            Ship section of Astrolabe is currently under development.
            Premium ships are missing, some stats are not displayed, skill recipes are missing.
        </UnderConstruction>

        <InfoPanel title={ship.name} icon={icon}>
            <Row>
                {/* Left column */}
                <Col md={6}>
                    {renderDescriptionSection(ship.description)}

                    {renderSkillsSection(ship.skills)}
                </Col>

                {/* Right column */}
                <Col md={6} className="mt-3 mt-md-0">
                    {renderPerformanceSection(ship)}

                    {renderCargoSection(ship)}

                    {renderBoardingRequirementsSection(ship)}
                    
                    {renderShipPartsSection(ship)}
                </Col>
            </Row>
        </InfoPanel>
    </>;
};

function renderDescriptionSection(description: string) {
    return <InfoPanelSection icon={faInfoCircle} text="Description">
        {description}
    </InfoPanelSection>;
}

function renderSkillsSection(skills: ShipSkillModel[]) {
    return <InfoPanelSection icon={faBadgeCheck} text="Optional skills">
        {skills.map((s, i) => <Row key={s.skill.name + '-' + i}>
            <Col>
                <SkillLink to={s.skill}/>
            </Col>
        </Row>)}
    </InfoPanelSection>;
}

function renderPerformanceSection(ship: ShipModel) {
    return <InfoPanelSection icon={faTachometer} text="Base performance">
        <Row noGutters>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.VerticalSail}/>
                &nbsp;
                {ship.verticalSail}
            </Col>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.HorizontalSail}/>
                &nbsp;
                {ship.horizontalSail}
            </Col>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.Rowing}/>
                &nbsp;
                {ship.rowPower}
            </Col>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.Durability}/>
                &nbsp;
                {ship.durability}
            </Col>
        </Row>
        <Row noGutters>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.TurnSpeed}/>
                &nbsp;
                {ship.turnSpeed}
            </Col>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.WaveResistance}/>
                &nbsp;
                {ship.waveResistance}
            </Col>
            <Col xs={3}>
                <ShipStatIcon type={ShipStatType.Armour}/>
                &nbsp;
                {ship.armour}
            </Col>
        </Row>
    </InfoPanelSection>;
}

function renderCargoSection(ship: ShipModel) {
    return <InfoPanelSection icon={faCubes} text="Ship hold">
        <Row>
            <Col xs={4}>
                <ShipStatIcon type={ShipStatType.Sailor}/>
                &nbsp;
                {ship.requiredSailors}/{ship.crewCabins}
            </Col>
            <Col xs={4}>
                <ShipStatIcon type={ShipStatType.Cannon}/>
                &nbsp;
                {ship.cannonChambers}
            </Col>
            <Col xs={4}>
                <ShipStatIcon type={ShipStatType.Cargo}/>
                &nbsp;
                {ship.cargoHold}
            </Col>
        </Row>
    </InfoPanelSection>;
}

function renderBoardingRequirementsSection(ship: ShipModel) {
    return <InfoPanelSection icon={faCheckSquare} text="Boarding requirements">
        <Row>
            <Col xs={4}>
                <PlayerClassIcon pClass={PlayerClass.Adventure}/>
                &nbsp;
                {ship.advReq || <>&mdash;</>}
            </Col>
            <Col xs={4}>
                <PlayerClassIcon pClass={PlayerClass.Trade}/>
                &nbsp;
                {ship.tradeReq || <>&mdash;</>}
            </Col>
            <Col xs={4}>
                <PlayerClassIcon pClass={PlayerClass.Battle}/>
                &nbsp;
                {ship.battleReq || <>&mdash;</>}
            </Col>
        </Row>
    </InfoPanelSection>;
}

function renderShipPartsSection(ship: ShipModel) {
    return <InfoPanelSection icon={faShieldAlt} text="Ship parts">
        <Row>
            <Col>
                Sails: {ship.studdingSailSlots}<br/>
                Armouring: {ship.armourSlots}<br/>
                Special: {ship.specialEquipmentSlots}
            </Col>

            <Col>
                Broadside: {ship.broadsideSlots}<br/>
                Bow: {ship.bowSlots}<br/>
                Stern: {ship.sternSlots}
            </Col>
        </Row>
    </InfoPanelSection>;
}

export default withObservableDataLoader<ShipModel, {key: string}>(
    ShipViewer,
    props => ShipService.getShipWithCache(props.match.params.key),
    (props, prevProps) => props.match.params.key !== prevProps.match.params.key
);
