import {DocumentModel} from "./model/document";
import _ from "lodash";
import { History, LocationState } from "history";
import {useEffect, useRef} from "react";

export function isTouchDevice() {
    return 'ontouchstart' in window;
}

export function hexToRgb(hex: string, alpha: number): string | undefined {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result === null) return undefined;
    return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)}` +
        `,${parseInt(result[3], 16)},${alpha}`;
}

export function makeLink(to: DocumentModel): string {
    if (to.type === "skill") {
        return `/${to.type}/${to.subtype}/${to.key}`;
    } else {
        return `/${to.type}/${to.key}`;
    }
}

export function navigate(history: History<LocationState>, ...routeParts: (string | undefined)[]) {
    const path = routeParts
        .map(part => part !== undefined ? `/${part}` : "")
        .join("");

    history.push(path);
}

export function generateKey(name: string) {
    return name.toLowerCase() //TODO: strip whitespace
        .replace(/[ _/\s']/g, "-")
        .replace(/[?!.,]/g, "");
}

export function mapToQueryString(data: any): string {
    return Object.keys(data).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    }).join("&");
}

export function generateHexNonce(length: number = 8): string {
    let nonceData = new Uint8Array(length);
    crypto.getRandomValues(nonceData);

    return _.map(nonceData, i => (i & 0xFF).toString(16)).join("");
}

export function upperCaseFirst(word: string) {
    return word.charAt(0).toUpperCase() + word.substr(1);
}

export function formatDucats(value: number | undefined | null): string {
    if (value === undefined || value === null) {
        return "-";
    }

    return value.toLocaleString();
}

export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function manipulateColor(col: string, amt: number, alpha?: string): string {
    let usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16) + (alpha||"");
}