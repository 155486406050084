import React from "react";
import {useLocation} from "react-router-dom";
import {AppState} from "../store/state";
import {useSelector} from "react-redux";
import {AnalyticsService} from "../services/AnalyticsService";

const Analytics: React.FC = () => {
    const userId = useSelector<AppState, string | undefined>(
        state => state && state.userInfo
            ? state.userInfo.userId
            : undefined);

    const location = useLocation();

    AnalyticsService.initialize();

    if (userId) {
        AnalyticsService.identUser(userId);
    }

    AnalyticsService.pageview(location.pathname);

    return null;
};

export default Analytics;
