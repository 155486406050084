import {Color, IconButton, withStyles} from "@material-ui/core";

const ColoredIconButton = (color: Color) => withStyles({
    root: {
        color: color[200],

        width: "2rem",
        height: "2rem"
    }
})(IconButton);

export default ColoredIconButton;