import React, {ReactElement} from "react";
import styles from "./TooltipCommon.module.scss";
import SkillTooltip from "./SkillTooltip";
import {LinkableDocumentModel} from "../../model/document";
import {OverlayTrigger, Popover} from "react-bootstrap";
import JobTooltip from "./JobTooltip";
import TradeGoodTooltip from "./TradeGoodTooltip";

interface ITooltipProps {
    target: LinkableDocumentModel;
}

const Tooltip: React.FC<ITooltipProps> = props => {
    const {target, children} = props;
    const tooltipComponent = typeToComponent(target);

    if (tooltipComponent) {
        const overlay = (overlayProps: any) =>
            <Popover {...overlayProps}
                     show={overlayProps.show.toString()}
                     className={styles.tooltipMain}>
                {tooltipComponent}
        </Popover>;

        return <OverlayTrigger placement="bottom"
                               delay={{show: 500, hide: 300}}
                               overlay={overlay}>
            {children as ReactElement}
        </OverlayTrigger>
    } else {
        return <>
            {children}
        </>;
    }
};

function typeToComponent(target: LinkableDocumentModel): any | undefined {
    const {type} = target;

    switch (type) {
        case "skill":
            return <SkillTooltip target={target}/>;
        case "job":
            return <JobTooltip target={target}/>;
        case "trade-good":
            return <TradeGoodTooltip target={target}/>;
        default:
            return undefined;
    }
}

export default Tooltip;
