import React, {useEffect, useState} from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import styles from './SkillBrowser.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import Loading from "../../fragments/Loading";
import {SkillModel} from "../../model/skill";
import SkillService from "../../services/SkillService";
import {navigate, upperCaseFirst} from "../../utils";
import PageHeaders from "../../widgets/PageHeaders";
import InfoPanel from "../../fragments/InfoPanel";
import SkillLine from "../../fragments/lines/SkillLine";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import SmartButton from "../../widgets/SmartButton";
import { ButtonGroup } from "@material-ui/core";

interface ISkillBrowserMatch {
    subtype: string;
}

interface IButtonDef {
    label: string;
    subtype: string;
    baseColor: string;
}

const BUTTON_DEFS: IButtonDef[][] = [
    [
        {
            baseColor: "#338833",
            subtype: "adventure",
            label: "Adventure"
        },
        {
            baseColor: "#999933",
            subtype: "trade",
            label: "Trade"
        },
        {
            baseColor: "#aa3333",
            subtype: "battle",
            label: "Battle"
        }
    ],
    [
        {
            baseColor: "#776633",
            subtype: "language",
            label: "Language"
        },
        {
            baseColor: "#999999",
            subtype: "aide",
            label: "Aide"
        },
        {
            baseColor: "#336699",
            subtype: "ship",
            label: "Ship"
        }
    ],
    [
        {
            baseColor: "#aa8f1e",
            subtype: "oxford",
            label: "Oxford"
        },
        {
            baseColor: "#10b75c",
            subtype: "boston",
            label: "Boston"
        }
    ]
];

const SkillBrowser: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [skills, setSkills] = useState<SkillModel[]>([]);

    const match = useRouteMatch<ISkillBrowserMatch>();
    const history = useHistory();

    function loadSkills(): void {
        const { subtype } = match.params;

        if (subtype) {
            setIsLoading(true);
            SkillService.getSkills(subtype)
                .then(skills => {
                    setSkills(skills);
                    setIsLoading(false);
                });
        }
    }

    useEffect(loadSkills, [match.params.subtype]);

    return <>
        {renderHeaders(match.params.subtype)}

        <InfoPanel title="Skill browser">
            <InfoPanelSection noHeader>
                Browse skills by selecting a category.
            </InfoPanelSection>

            <InfoPanelSection noHeader>
                <Row>
                    <Col>
                        {BUTTON_DEFS.map((row, i) =>
                            <div key={i}>
                                <ButtonGroup>
                                    {row.map((btn, ii) =>
                                        <SmartButton key={ii}
                                                     baseColor={btn.baseColor}
                                                     isSelected={btn.subtype === match.params.subtype}
                                                     onClick={() => navigate(history, "skills", btn.subtype)}
                                                     className={styles.subclassBtn}>
                                            {btn.label}
                                        </SmartButton>)}
                                </ButtonGroup>
                            </div>)}
                    </Col>
                </Row>
            </InfoPanelSection>
        </InfoPanel>

        {isLoading && <Loading/>}

        {!isLoading && <Container>
            {skills.map(skill =>
                <SkillLine key={skill.key} skill={skill}/>)}
        </Container>}
    </>;
};

export default SkillBrowser;

function renderHeaders(subtype: string | undefined) {
    if (subtype === undefined) {
        return <PageHeaders title="Skills"/>;
    } else {
        return <PageHeaders title={`${upperCaseFirst(subtype)} skills`}/>;
    }
}
