import React, {useEffect, useState} from "react";
import {useRouteMatch, match} from "react-router-dom";
import Loading from "../fragments/Loading";
import {usePrevious} from "../utils";
import {from, Observable} from "rxjs";

export interface IDataProps<T> {
    data: T
}

type PropsWithMatch<TProps, TMatch> = TProps & {
    match: match<TMatch>;
}

export const withObservableDataLoader = <TData, TMatch={}, TProps={}>(
        WrappedComponent: React.ComponentType<TProps & IDataProps<TData>>,
        dataSource: (props: PropsWithMatch<TProps, TMatch>) => Observable<TData>,
        shouldReload: (props: PropsWithMatch<TProps, TMatch>, prevProps: PropsWithMatch<TProps, TMatch>) => boolean = () => false,
        loadingRender: (props: PropsWithMatch<TProps, TMatch>) => any = () => <Loading/>): React.FC<TProps> =>
    (props: TProps) => {
        const [isLoading, setLoading] = useState<boolean>(false);
        const [data, setData] = useState<TData | undefined>(undefined);

        const match = useRouteMatch<TMatch>();

        const currProps = {...props, match};
        const prevProps = usePrevious(currProps);

        useEffect(() => {
            if (prevProps === undefined || shouldReload(currProps, prevProps)) {
                setLoading(true);

                dataSource(currProps)
                    .subscribe(data => {
                        setData(data);
                        setLoading(false);
                    });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currProps]);

        if (isLoading) {
            return loadingRender(currProps);
        } else if (data !== undefined) {
            return <WrappedComponent data={data} {...props as TProps}/>;
        } else {
            return null;
        }
};

export const withPromiseDataLoader = <TData, TMatch={}, TProps={}>(
    WrappedComponent: React.ComponentType<TProps & IDataProps<TData>>,
    dataLoader: (props: PropsWithMatch<TProps, TMatch>) => Promise<TData>,
    shouldReload: (props: PropsWithMatch<TProps, TMatch>, prevProps: PropsWithMatch<TProps, TMatch>) => boolean = () => false,
    loadingRender: (props: PropsWithMatch<TProps, TMatch>) => any = () => <Loading/>): React.FC<TProps> =>
    withObservableDataLoader<TData, TMatch, TProps>(
        WrappedComponent,
        props => from(dataLoader(props)),
        shouldReload,
        loadingRender
    );
