import React from "react";
import {IEditorComponentProps} from "./common";
import {Col, Row} from "react-bootstrap";
import AstrolabeCheckbox from "../fragments/AstrolabeCheckbox";
import {FormControlLabel} from "@material-ui/core";

type IBooleanEditorProps = IEditorComponentProps<boolean> & {
    label: string;
};

const BooleanEditor: React.FC<IBooleanEditorProps> = props => {
    const {value, onStateUpdate, label} = props;

    return <Row className="mb-1">
        <Col>
            <FormControlLabel control={
                <AstrolabeCheckbox value={value}
                                   onChange={newValue => onStateUpdate({$set: newValue})}/>}
                              label={label}/>
        </Col>
    </Row>;
};

export default BooleanEditor;