import React from "react";
import {NewsPostModel} from "../model/news";
import {Col, Row} from "react-bootstrap";
import styles from "./NewsPost.module.scss";
import moment from "moment";
import AstrolabeMarkdown from "./AstrolabeMarkdown";
import {AppState} from "../store/state";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/pro-duotone-svg-icons";
import {canEditNews} from "../permissions";
import ColoredIconButton from "./ColoredIconButton";
import {blue} from "@material-ui/core/colors";

interface INewsPostProps {
    post: NewsPostModel | undefined;
    forceCanEdit?: boolean;
}

const NewsPost: React.FC<INewsPostProps> = props => {
    const {post, forceCanEdit} = props;

    const canEdit = useSelector<AppState, boolean>(
        state => state && canEditNews(state.userInfo));

    const computedCanEdit = forceCanEdit !== undefined ? forceCanEdit : canEdit;

    if (post === undefined) return null;

    const EditButton = ColoredIconButton(blue);

    return <>
        <Row>
            <Col xs={10}>
                <h5>{post.title}</h5>
            </Col>

            <Col xs={2} className="text-right">
                {computedCanEdit &&
                    <Link to={`/news/${post.id}/edit`}>
                        <EditButton size="small"
                                    title="Edit">
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </EditButton>
                    </Link>}
            </Col>
        </Row>

        <Row>
            <Col className={styles.postDetails}>
                by <span className={styles.posterDisplayName}>{post.poster.displayName}</span>
                {" "}&mdash;{" "}
                {moment(post.postDate).fromNow()}
            </Col>
        </Row>

        <Row>
            <Col className={styles.postContent}>
                <AstrolabeMarkdown source={post.content}/>
            </Col>
        </Row>
    </>;
};

export default NewsPost;