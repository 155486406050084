import React, {ChangeEvent, useEffect, useState, WheelEvent} from "react";
import styles from "./ScrollableIntegerInput.module.scss";
import {Input} from "@material-ui/core";

interface IScrollableIntegerInputProps {
    value: number | null | undefined;
    onChange: (newValue: number | null) => void;

    disabled?: boolean;
    placeholder?: string;
}

type InputChange = ChangeEvent<HTMLInputElement>;

const ScrollableIntegerInput: React.FC<IScrollableIntegerInputProps> = props => {
    const {value, onChange, disabled, placeholder} = props;

    const [textInput, setTextInput] = useState<HTMLInputElement | null>(null);

    useEffect(() => {
        if (textInput === null) return;

        const listener = inputWheel(() => parseInt(textInput!.value), onChange);
        textInput.addEventListener("wheel", listener, {passive: false});

        return () => {
            if (textInput === null) return;
            textInput.removeEventListener("wheel", listener);
        }
    });

    return <Input value={value === undefined || value === null ? "" : value.toString()}
                  placeholder={placeholder}
                  className={styles.input}
                  disabled={disabled}
                  onChange={(ev: InputChange) =>
                      inputChanged(ev.target.value, onChange)}
                  ref={(input: any) => {
                      if (input) {
                          setTextInput(input.getElementsByTagName("input")[0] as HTMLInputElement);
                      } else {
                          setTextInput(null);
                      }
                  }}/>;
};

export default ScrollableIntegerInput;

function inputChanged(newValue: string, onChange: (newValue: number | null) => void) {
    if (newValue === "") return onChange(null);
    if (isNaN(newValue as any)) return;
    onChange(parseInt(newValue));
}

function inputWheel (getter: () => number | null | undefined,
                     onChange: (newValue: number | null) => void): any {
    return (event: WheelEvent<HTMLInputElement>) => {
        if ((event.target as any).disabled) {
            event.preventDefault();
            return;
        }

        let value = getter();
        if (value === undefined || value === null || isNaN(value))
        {
            if (event.deltaY > 0) return;

            value = 0;
        }

        const desiredValue = Math.round(value - event.deltaY / 100);

        event.preventDefault();

        inputChanged(desiredValue.toString(), onChange);
    };
}