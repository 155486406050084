import {Observable} from "rxjs";
import {RecipeBookModel} from "../model/recipe-book";
import BackendService from "./BackendService";
import {map} from "rxjs/operators";

const RecipeService = {
    getRecipeBookWithCache(key: string): Observable<RecipeBookModel> {
        return BackendService.getObservableWithCache<RecipeBookModel>(`/recipe-book/${key}`)
            .pipe(map(response => response.data));
    }
}

export default RecipeService;
