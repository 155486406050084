import React, {ChangeEvent} from "react";
import styles from "./TextEditor.module.scss";
import {IEditorComponentProps} from "./common";
import {Input, TextField} from "@material-ui/core";

type InputChange = ChangeEvent<HTMLInputElement>;

type ITextEditorProps = IEditorComponentProps<string> & {
    textarea?: boolean;
    placeholder?: string;
    rowsMax?: number
}

const TextEditor: React.FC<ITextEditorProps> = props => {
    const {value, onStateUpdate, textarea, placeholder, rowsMax} = props;

    if (textarea) {
        return <TextField multiline
                          rowsMax={rowsMax || 3}
                          className={styles.input}
                          value={value}
                          placeholder={placeholder}
                          onInput={(ev: InputChange) => onStateUpdate({$set: ev.target.value})}/>;
    } else {
        return <Input className={styles.input}
                      value={value}
                      placeholder={placeholder}
                      onChange={(ev: InputChange) => onStateUpdate({$set: ev.target.value})}/>;
    }
};

export default TextEditor;