import React from "react";
import {IDocumentLine, LineItem} from "./DocumentLine";
import {JobModel} from "../../model/job";
import {Col} from "react-bootstrap";
import InlineLink from "../InlineLink";
import styles from "../../pages/jobs/JobBrowser.module.scss";
import SkillLink from "../SkillLink";
import Level from "../Level";
import {PlayerClass} from "../../model/common";

const JobLine: IDocumentLine<{job: JobModel}> = props => {
    const {job, noSeparator, noTooltip, hideIcon} = props;

    return <LineItem noSeparator={noSeparator}>
        <Col xs={5} lg={3}>
            <InlineLink className={styles.jobLink}
                        to={job}
                        hideIcon={hideIcon}
                        suppressTooltip={noTooltip}/>
        </Col>

        <Col xs={7} lg={3}>
            <div className={styles.levelReq}>
                <Level clazz={PlayerClass.Adventure} level={job.advReq}/>
            </div>
            <div className={styles.levelReq}>
                <Level clazz={PlayerClass.Trade} level={job.tradeReq}/>
            </div>
            <div className={styles.levelReq}>
                <Level clazz={PlayerClass.Battle} level={job.battleReq}/>
            </div>
            <div className={styles.levelReq}>
                <Level clazz="total" level={job.totalReq}/>
            </div>
        </Col>

        <Col xs={{span: 2, offset: 1}} sm={{span: 1}} lg={{span: 1, offset: 0}}>
            {job.expertSkill &&
            <SkillLink to={job.expertSkill}
                       showName={false}/>}
        </Col>

        <Col xs={9} sm={10} lg={4}>
            {job.favouredSkills.map(s =>
                <SkillLink key={s.key}
                           to={s}
                           showName={false}/>
            )}
        </Col>
    </LineItem>;
};

export default JobLine;