import React, {useState} from "react";
import commonStyles from "../styles/common.module.scss";
import {BaseSkillModel} from "../model/skill";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import SearchService from "../services/SearchService";
import SelectedSkillItem from "../fragments/SelectedSkillItem";
import AssetImage from "../fragments/AssetImage";

interface IMultiSkillSelectorProps {
    placeholder?: string;
    subtypes: string[];
    selectedSkills: BaseSkillModel[];
    onSelectionChanged: (skills: BaseSkillModel[]) => void;
}

const MultiSkillSelector: React.FC<IMultiSkillSelectorProps> = props =>{
    const {placeholder, subtypes, selectedSkills, onSelectionChanged} = props;

    const [options, setOptions] = useState<BaseSkillModel[]>([]);

    return <>
        <Autocomplete multiple
                      value={selectedSkills}
                      options={options}
                      renderInput={params => <TextField className={commonStyles.fullWidth}
                                                        placeholder={placeholder}
                                                        {...params}/>
                      }
                      getOptionLabel={skill => skill.name}
                      renderOption={skill => <>
                          <AssetImage item={skill}/>
                          &nbsp;
                          {skill.name}
                      </>}
                      renderTags={skills => renderTags(skills, onSelectionChanged)}
                      onInputChange={(ev, input) => {
                          if (input.length >= 3) {
                              getSkills(input, subtypes, setOptions)
                          } else {
                              setOptions([]);
                          }
                      }}
                      onChange={(ev, skills) => {
                          setOptions([]);
                          onSelectionChanged(skills);
                      }}
                      />
    </>;
};

function getSkills(term: string,
                   subtypes: string[],
                   callback: (skills: BaseSkillModel[]) => void) {
    SearchService.search(term, ["skill"], subtypes)
        .then(skills => callback(skills as BaseSkillModel[]));
}

function renderTags(skills: BaseSkillModel[], callback: (skills: BaseSkillModel[]) => void) {
    return skills.map((skill, idx) =>
        <SelectedSkillItem key={skill.key}
                           skill={skill}
                           removalHandler={() => {
                               skills.splice(idx, 1);
                               callback(skills);
                           }}/>)
}

export default MultiSkillSelector;
