import React from "react";
import PageHeaders from "../widgets/PageHeaders";
import {faCopyright} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const About: React.FC = () => {
    return <>
        <PageHeaders title="About"/>

        <h4>About Astrolabe</h4>

        <p>
            Astrolabe is an unofficial <a href="https://uwo.papayaplay.com/uwo.do">Uncharted Waters Online</a> Database.
            The goals of this project are:
        </p>
        <ul>
            <li>
                Create and maintain an accurate and up-to-date knowledge base about all aspects of the MMORPG Uncharted Waters Online.
                Specifically, the target is the version running on the "Maris" server for english-speaking players, operated by Papaya Play.
            </li>
            <li>
                Allow users to update existing inaccurate content and extend the database with new content based on their in-game experience.
                Develop a community-driven system for rating the accuracy of submissions.
            </li>
            <li>
                Seed as much data as possible automatically (mostly from the barely-maintained "Ivyro UWODB")
                to reduce manual effort of making the database usable.
            </li>
            <li>
                Leverage modern web technologies to deliver the best possible user experience, both on desktop, as well as mobile.
            </li>
        </ul>

        <h4>Credits</h4>

        <ul>
            <li>
                In-game graphics are <FontAwesomeIcon icon={faCopyright}/> Koei Tecmo Games.
            </li>
            <li>
                This website uses <a href="https://fontawesome.com/">FontAwesome icons</a> under a Standard license.
            </li>
        </ul>
    </>;
};

export default About;
