import {SkillModel} from "../model/skill";
import BackendService from "./BackendService";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';

const SkillService = {
    getSkill(subtype: string, key: string): Promise<SkillModel> {
        return BackendService.get<SkillModel>(`/skill/${subtype}/${key}`)
            .then(response => response.data);
    },

    getSkillWithCache(subtype: string, key: string): Observable<SkillModel> {
        return BackendService.getObservableWithCache<SkillModel>(`/skill/${subtype}/${key}`)
            .pipe(map(response => response.data));
    },

    putSkill(skill: SkillModel, accessToken: string): Promise<void> {
        const {subtype, key} = skill;
        return BackendService.put<void>(`/skill/${subtype}/${key}`, skill, accessToken)
            .then();
    },

    getSkills(subtype: string): Promise<SkillModel[]> {
        return BackendService.get<SkillModel[]>(`/skills/${subtype}`)
            .then(response => response.data);
    }
};

export default SkillService;