import {DocumentModel, LinkableDocumentModel} from "./document";
import {RecipeModel} from "./recipe";
import {PersonLocationModel} from "./common";
import {AideDuty} from "./aide";

export interface SkillRankModel {
    skill: BaseSkillModel;
    rank: number | null;
}

export interface BaseSkillModel extends DocumentModel {
    type: 'skill';
    subtype: string;

    name: string;
    description: string;
}

export type PlayerSkillModel = BaseSkillModel & {
    subtype: 'adventure' | 'trade' | 'battle';

    vigour: number | null | undefined;
    advReq: number | null | undefined;
    tradeReq: number | null | undefined;
    battleReq: number | null | undefined;
    cost: number;

    requiredSkills: SkillRankModel[];

    teachers: PersonLocationModel[];

    expertJobs: LinkableDocumentModel[];
    favouringJobs: LinkableDocumentModel[];

    affectingSkills?: BaseSkillModel[];
} & ({
    refinementPossible: true;
    refinementEffects: string[];
} | {
    refinementPossible: false | undefined;
    refinementEffects: undefined;
})

export interface LanguageSkillModel extends BaseSkillModel {
    subtype: 'language';

    cost: number;

    teachers: PersonLocationModel[];
}

export interface AideSkillModel extends BaseSkillModel {
    subtype: 'aide';

    aideDuty?: AideDuty;
}

export interface ShipSkillModel extends BaseSkillModel {
    subtype: 'ship';

    vigour: number;

    requiredSkills: SkillRankModel[];
    originalShipRecipe: RecipeModel;

    optionalOnShips: LinkableDocumentModel[];
}

export interface OxfordSkillModel extends BaseSkillModel {
    subtype: "oxford"

    affectsSkills: BaseSkillModel[];
}

export interface BostonSkillModel extends BaseSkillModel {
    subtype: "boston";

    job?: LinkableDocumentModel;
    affectsSkills: BaseSkillModel[];
}

export type SkillModel =
    PlayerSkillModel | LanguageSkillModel |
    AideSkillModel |
    ShipSkillModel |
    OxfordSkillModel | BostonSkillModel;

export function isCorePlayerSkill(skill: BaseSkillModel | undefined): boolean {
    if (skill === undefined) return false;

    return ['adventure', 'trade', 'battle'].includes(skill.subtype!);
}

export function isPlayerSkill(skill: BaseSkillModel | undefined): boolean {
    if (skill === undefined) return false;

    return ['adventure', 'trade', 'battle', 'language'].includes(skill.subtype!);
}

export function isRefinementPossible(skill: SkillModel): boolean {
    if (!isCorePlayerSkill(skill)) return false;

    return (skill as PlayerSkillModel).refinementPossible === true;
}
