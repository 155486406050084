import React from "react";
import ducatsIcon from "../assets/ducats.png";
import {formatDucats} from "../utils";

const Ducats: React.FC<{amount: number | undefined | null}> = props => {
    const {amount} = props;

    return <span>
        <img src={ducatsIcon} title="Ducats" alt="ducats"/>
            &nbsp;
        {formatDucats(amount)}
    </span>;
};

export default Ducats;