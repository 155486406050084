import React from "react";
import styles from "./DocumentLine.module.scss";
import {DocumentModel, LinkableDocumentModel} from "../../model/document";
import QuestLine from "./QuestLine";
import {QuestModel} from "../../model/quest";
import InlineLink from "../InlineLink";
import {Col, Row} from "react-bootstrap";
import JobLine from "./JobLine";
import {JobModel} from "../../model/job";
import SkillLine from "./SkillLine";
import {SkillModel} from "../../model/skill";
import ShipLine from "./ShipLine";
import {ShipModel} from "../../model/ship";

interface IDocumentLineProps {
    noTooltip?: boolean;
    noSeparator?: boolean;
    hideIcon?: boolean;
}

export interface IDocumentLine<T>
    extends React.FC<T & IDocumentLineProps> {}


const DocumentLine: React.FC<{target: DocumentModel} & IDocumentLineProps> = props => {
    const {target, noSeparator, noTooltip, hideIcon} = props;

    switch (target.type) {
        case "skill":
            return <SkillLine skill={target as SkillModel} {...props}/>;
        case "job":
            return <JobLine job={target as JobModel} {...props}/>;
        case "quest":
            return <QuestLine quest={target as QuestModel} {...props}/>;
        case "ship":
            return <ShipLine ship={target as ShipModel} {...props}/>;
        default:
            return <LineItem noSeparator={noSeparator}>
                <Col>
                    <InlineLink to={target as LinkableDocumentModel}
                                suppressTooltip={noTooltip}
                                hideIcon={hideIcon}/>
                </Col>
            </LineItem>;
    }
};

export default DocumentLine;

export const LineItem: React.FC<{noSeparator?: boolean}> = props => {
    const useSeparator = props.noSeparator !== true;

    return <Row noGutters className={useSeparator ? styles.lineItem : undefined}>
        {props.children}
    </Row>
};
