import React, {useEffect, useState} from "react";
import styles from "./QuestBrowser.module.scss";
import commonStyles from "../../styles/common.module.scss";
import {QuestModel} from "../../model/quest";
import {Col, Container, Row} from "react-bootstrap";
import MetadataService from "../../services/MetadataService";
import {QuestBrowserMetadataModel, QuestLocationMetadataModel} from "../../model/metadata";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {faCircle, faExclamationTriangle, faMale, faMapMarkerAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import Loading from "../../fragments/Loading";
import QuestService, {IGetQuestsRequest} from "../../services/QuestService";
import PageHeaders from "../../widgets/PageHeaders";
import UnderConstruction from "../../fragments/UnderConstruction";
import InfoPanel from "../../fragments/InfoPanel";
import QuestLine from "../../fragments/lines/QuestLine";
import {ButtonGroup, FormControlLabel} from "@material-ui/core";
import {IBreakpointMatches, useBreakpoints} from "../../widgets/BreakpointProvider";
import AstrolabeCheckbox from "../../fragments/AstrolabeCheckbox";
import SmartButton from "../../widgets/SmartButton";


const resultLimit = 50; //TODO: send to API

const QuestBrowser: React.FC = () => {
    const [isLoadingMetadata, setIsLoadingMetadata] = useState<boolean>(false);
    const [metadata, setMetadata] = useState<QuestBrowserMetadataModel | undefined>(undefined);

    const [selectedQuestGiver, setSelectedQuestGiver] = useState<string | undefined>(undefined);
    const [allowMultipleLocations, setAllowMultipleLocations] = useState<boolean>(false);
    const [selectedQuestLocations, setSelectedQuestLocations] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [quests, setQuests] =useState<QuestModel[]>([]);

    const breakpoints = useBreakpoints();

    useEffect(loadMetadata, []);

    useEffect(
        loadQuests,
        [selectedQuestGiver, selectedQuestLocations]
    );

    function loadMetadata() {
        setIsLoadingMetadata(true);

        MetadataService.getQuestBrowserMetadata()
            .then(metadata => {
                setMetadata(metadata);
                setIsLoadingMetadata(false);
            });
    }

    function loadQuests() {
        if (isSearchValid()) {
            setIsLoading(true);
            setQuests([]);

            const request: IGetQuestsRequest = {};

            if (selectedQuestGiver) {
                request.questGiver = selectedQuestGiver;
            }

            if (selectedQuestLocations.length > 0) {
                request.questLocations = selectedQuestLocations;
            }

            QuestService.getQuests(request)
                .then(quests => {
                    setIsLoading(false);
                    setQuests(quests);
                });
        }
    }

    function handleQuestGiverSelected(selectedQuestGiver: string | undefined) {
        setSelectedQuestGiver(selectedQuestGiver);
    }

    function handleLocationSelected(location: string | undefined) {
        if (location === undefined) {
            setSelectedQuestLocations([]);
        } else {
            if (allowMultipleLocations) {
                if (_.indexOf(selectedQuestLocations, location) >= 0) {
                    setSelectedQuestLocations(
                        _.without(selectedQuestLocations, location));
                } else {
                    setSelectedQuestLocations(
                        [...selectedQuestLocations, location]);
                }
            } else {
                setSelectedQuestLocations([location]);
            }
        }
    }

    function handleMultipleClicked() {
        if (allowMultipleLocations) {
            if (selectedQuestLocations.length > 1) {
                setAllowMultipleLocations(false);
                setSelectedQuestLocations([]);
            } else {
                setAllowMultipleLocations(false);
            }
        } else {
            setAllowMultipleLocations(true);
        }
    }

    function isSearchValid(): boolean {
        if (selectedQuestGiver) {
            return true;
        }

        return selectedQuestLocations.length > 0;
    }

    return <>
        <PageHeaders title="Quests"/>

        <UnderConstruction>
            Quest section of Astrolabe is currently under development and is only available for preview.
            Only a minor portion of quests are available, information may be missing or inaccurate, links to discoveries are dead.
        </UnderConstruction>

        <InfoPanel title="Quest browser">
            <Row>
                <Col>
                    Search for quests by selecting criteria using buttons below.
                    {isLoadingMetadata && <Loading/>}
                </Col>
            </Row>

            {metadata && renderQuestGiverSection(
                metadata,
                selectedQuestGiver,
                handleQuestGiverSelected,
                breakpoints)}

            {metadata && renderContractLocationSection(
                metadata,
                selectedQuestLocations,
                allowMultipleLocations,
                handleLocationSelected,
                handleMultipleClicked,
                breakpoints)}
        </InfoPanel>

        {isLoading && <Loading/>}

        {!isLoading && !isSearchValid() &&
        <div className={styles.resultsInformation}>
            At least one specific selection is required to perform a search.
        </div>}

        {!isLoading && isSearchValid() && quests.length === 0 &&
        <div className={styles.resultsInformation}>
            No quests found matching the selected criteria.
        </div>}

        {!isLoading && quests.length >= resultLimit &&
        <div className={styles.resultsInformation}>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
            &nbsp;
            There is a limit of {resultLimit} results returned. Please narrow down your search criteria.
        </div>}

        {isSearchValid() && quests.length > 0 && renderQuestListSection(quests)}
    </>;
};

export default QuestBrowser;

function renderQuestGiverSection(metadata: QuestBrowserMetadataModel,
                                 selectedQuestGiver: string | undefined,
                                 handleQuestGiverSelected: (questGiver: string | undefined) => void,
                                 breakpoints: IBreakpointMatches) {
    const breakPointColumns: BreakPointColumns = {
        xs: 2,
        sm: 4,
        md: 6,
        lg: 8,
        xl: 10
    };

    const columns = breakpointsToColumns(breakpoints, breakPointColumns);

    const reflowedButtons: QuestLocationMetadataModel[][] = reflowGroups([[{
        name: "All",
        buttonColor: "#888888"
    }, ...metadata.questGivers]], columns)[0];

    return <>
        <InfoPanelSection icon={faMale}
                          faMask={faCircle}
                          faTransform="shrink-4"
                          text="Mediator type"/>

        {reflowedButtons.map((group, ii) =>
            <div key={ii}>
                <ButtonGroup className={commonStyles.fullWidth}>
                    {group.map((btn, iii) =>
                        <SmartButton key={iii}
                                     baseColor={btn.buttonColor}
                                     isSelected={isQuestGiverSelected(
                                         btn.name === "All" ? undefined : btn.name,
                                         selectedQuestGiver)}
                                     onClick={() => handleQuestGiverSelected(btn.name === "All" ? undefined : btn.name)}
                                     style={{width: `${Math.floor(100 / columns)}%`}}>
                            {btn.name.replace(" Mediator", "")}
                        </SmartButton>)}
                </ButtonGroup>
            </div>)}
    </>;
}

function isQuestGiverSelected(questGiver: string | undefined,
                              selectedQuestGiver: string | undefined): boolean {
    return selectedQuestGiver === questGiver;
}

function renderContractLocationSection(metadata: QuestBrowserMetadataModel,
                                       selectedLocations: string[],
                                       allowMultipleLocations: boolean,
                                       handleLocationSelected: (location: string | undefined) => void,
                                       handleMultipleClicked: () => void,
                                       breakpoints: IBreakpointMatches) {
    const breakPointColumns: BreakPointColumns = {
        xs: 2,
        sm: 3,
        md: 4,
        lg: 6,
        xl: 7,
    };

    const columns = breakpointsToColumns(breakpoints, breakPointColumns);

    const reflowedButtons = reflowGroups(metadata.questLocations, columns);

    return <>
        <InfoPanelSection icon={faMapMarkerAlt}
                          text="Mediator location"/>

        <Row>
            <Col className={styles.locationTopContainer}>
                <ButtonGroup className="mb-1">
                    <SmartButton baseColor="#888888"
                                 isSelected={isLocationSelected(undefined, selectedLocations)}
                                 onClick={() => handleLocationSelected(undefined)}>
                        Any
                    </SmartButton>
                </ButtonGroup>

                <FormControlLabel
                    control={
                        <AstrolabeCheckbox value={allowMultipleLocations}
                                           onChange={handleMultipleClicked}/>
                    }
                    className={styles.multipleContainer}
                    label="Select multiple"/>
            </Col>
        </Row>

        {reflowedButtons.map((row, i) =>
            <div key={i} className="mb-1">
                {row.map((group, ii) =>
                    <div key={ii}>
                        <ButtonGroup className={commonStyles.fullWidth}>
                            {group.map((btn, iii) =>
                                <SmartButton key={iii}
                                             baseColor={btn.buttonColor}
                                             isSelected={isLocationSelected(btn.name, selectedLocations)}
                                             onClick={() => handleLocationSelected(btn.name)}
                                             style={{width: `${Math.floor(100 / columns)}%`}}>
                                    {btn.name}
                                </SmartButton>)}
                        </ButtonGroup>
                    </div>)}
            </div>
        )}
    </>;
}

function isLocationSelected(location: string | undefined,
                            selectedLocations: string[]): boolean {
    if (selectedLocations.length === 0) {
        return location === undefined;
    }

    return _.find(selectedLocations, loc => loc === location) !== undefined;
}

function renderQuestListSection(quests: QuestModel[]) {
    return <Container>
        {quests.map(quest =>
            <QuestLine key={quest.key}
                       quest={quest}
                       hideIcon/>
        )}
    </Container>;
}




interface BreakPointColumns {
    xs: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
}

function breakpointsToColumns(breakpoints: IBreakpointMatches,
                              columns: BreakPointColumns): number {
    if (!breakpoints.sm) return columns.xs;
    if (!breakpoints.md) return columns.sm;
    if (!breakpoints.lg) return columns.md;
    if (!breakpoints.xl) return columns.lg;
    return columns.xl;
}

function reflowGroups<T>(groups: T[][], columns: number): T[][][] {
    return groups.map(group => {
        const result: T[][] = [];

        while (group.length > 0) {
            const newGroup = group.slice(0, columns);
            result.push(newGroup);

            group = group.slice(columns);
        }

        return result;
    });
}