import React from "react";
import styles from "./SelectedSkillItem.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import {BaseSkillModel} from "../model/skill";
import {IconButton} from "@material-ui/core";
import AssetImage from "./AssetImage";

interface ISelectedSkillItemProps {
    skill: BaseSkillModel;
    removalHandler: (skill: BaseSkillModel) => void;
}

const SelectedSkillItem: React.FC<ISelectedSkillItemProps> = props => {
    const {skill, removalHandler} = props;

    return <div className={styles.selectedSkill}>
        <AssetImage item={skill}/>
        &nbsp;
        {skill.name}
        <IconButton size="small"
                    onClick={() => removalHandler(skill)}>
            <FontAwesomeIcon icon={faTimes}/>
        </IconButton>
    </div>;
};

export default SelectedSkillItem;
