import React from "react";
import styles from "./Level.module.scss";
import {PlayerClass} from "../model/common";
import {faPlusSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlayerClassIcon from "./PlayerClassIcon";
import {upperCaseFirst} from "../utils";

interface ILevelProps {
    clazz: PlayerClass | "total";
    level: number | undefined | null;
}

const Level: React.FC<ILevelProps> = props => {
    const {clazz, level} = props;

    let icon: any;
    if (clazz === "total") {
        icon = <FontAwesomeIcon icon={faPlusSquare} title="Total level"/>;
    } else {
        icon = <PlayerClassIcon pClass={clazz} title={upperCaseFirst(clazz) + " level"}/>;
    }

    return <span className={styles.level}>
        {icon}
        &nbsp;
        {level || <>&mdash;</>}
    </span>;
};

export default Level;