import {NewsPostModel} from "../model/news";
import BackendService from "./BackendService";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

interface INewsService {
    getNewsPost(newsId: string): Promise<NewsPostModel>;

    getFrontNews(): Observable<NewsPostModel[]>;

    postNews(id: string, title: string, content: string, accessToken: string): Promise<void>;
}

class NewsService implements INewsService {
    public getNewsPost(newsId: string): Promise<NewsPostModel> {
        return BackendService.get<NewsPostModel>(`/news/${newsId}`)
            .then(response => response.data);
    }

    public getFrontNews(): Observable<NewsPostModel[]> {
        return BackendService.getObservableWithCache<NewsPostModel[]>("/news")
            .pipe(map(response => response.data));
    }

    public postNews(id: string, title: string, content: string, accessToken: string): Promise<void> {
        return BackendService.put<void>(`/news/${id}`, {
            title,
            content
        }, accessToken)
            .then();
    }
}

export default new NewsService();