import React from "react";
import {DocumentModel} from "../model/document";

interface AssetImageProps {
    item: DocumentModel;

    extension?: string;
}

const AssetImage : React.FC<AssetImageProps> = props => {
    const {item, extension} = props;

    const ext = extension ? extension : "gif";

    if (item.subtype) {
        return <img src={`https://assets.astrolabe.fyi/icons/${item.type}/${item.subtype}/${item.key}.${ext}`}
                    alt={item.key}/>;
    } else {
        return <img src={`https://assets.astrolabe.fyi/icons/${item.type}/${item.key}.${ext}`}
                    alt={item.key}/>;
    }
}

export default AssetImage;
