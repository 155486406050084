import React, {useState} from "react";
import {
    Switch,
    Route, Link
} from "react-router-dom";
import styles from "./App.module.scss";
import astrolabeIcon from "./astrolabe.png";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "react-sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";
import NotImplemented from "./fragments/NotImplemented";
import CookieNotice from "./fragments/CookieNotice";

import {
    About,
    AuthCallback,
    Home,
    LogoutCallback,
    Search,
    UserProfileEditor,

    JobBrowser, JobViewer,
    NewsEditor,
    QuestBrowser, QuestViewer,
    RecipeBookViewer,
    ShipBrowser, ShipViewer,
    SkillBrowser, SkillEditor, SkillViewer,
    TradeGoodViewer,
    WikiEditor, WikiViewer
} from "./pages";

import {
    OmniSearchBar,
    PageHeaders,
    ScrollToTopButton,
    UserInfo
} from "./widgets";
import {NoSsr} from "@material-ui/core";
import {useBreakpoints} from "./widgets/BreakpointProvider";

const App: React.FC = () => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    const breakpoints = useBreakpoints();

    return <>
        <PageHeaders/>

        <Sidebar sidebar={renderSidebarContent(() => setSidebarOpen(false))}
                 open={sidebarOpen}
                 docked={breakpoints.md}
                 onSetOpen={open => setSidebarOpen(open)}
                 styles={{sidebar: {backgroundColor: "rgba(0,35,64,0.8)"}}}>
            <ScrollToTopButton/>

            <Container id="appContainer"
                       className={styles.appContainer}>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={{span: 2, order: 1}}
                                 md={{span: 12, order: 3}}
                                 className="d-md-none mt-1">
                                <FontAwesomeIcon icon={faBars}
                                                 onClick={() => setSidebarOpen(true)}/>
                            </Col>
                            <Col xs={{span: 12, order: 3}}
                                 md={{span: 6, order: 1}}
                                 className="align-self-end mb-2">
                                <OmniSearchBar/>
                            </Col>

                            <Col xs={{span: 10, order: 2}}
                                 md={{span: 6, order: 2}}
                                 className="text-right mb-2">
                                <UserInfo/>
                            </Col>
                        </Row>

                        <Row>
                            <Col className={styles.mainContent}>
                                {renderRouteSwitch()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Sidebar>

        <NoSsr defer>
            <CookieNotice/>
        </NoSsr>
    </>;
};

export default App;

function renderSidebarContent(linkClickHandler: () => void) {
    return <Container className={styles.sidebar}>
        <Row className="mt-2">
            <Col>
                <img className={styles.centerBlock}
                     src={astrolabeIcon}
                     alt="astrolabe"/>

            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <Link to={"/"} onClick={linkClickHandler}>Home</Link>
                <br/>
                <Link to={"/about"} onClick={linkClickHandler}>About</Link>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <Link to={"/skills"} onClick={linkClickHandler}>Skills</Link>
                <br/>
                <Link to={"/jobs"} onClick={linkClickHandler}>Jobs</Link>
            </Col>
        </Row>

        <Row className="mt-3">
            <Col>
                <Link to={"/quests"} onClick={linkClickHandler}>Quests</Link>
                <br/>
                <Link to={"/ships"} onClick={linkClickHandler}>Ships</Link>
            </Col>
        </Row>
    </Container>;
}

function renderRouteSwitch() {
    return <Switch>
        <Route path="/" exact component={Home}/>

        <Route path="/skills" exact component={SkillBrowser}/>
        <Route path="/skills/:subtype" exact component={SkillBrowser}/>
        <Route path="/skill/:subtype/:key" exact component={SkillViewer}/>
        <Route path="/skill/:subtype/:key/edit" exact component={SkillEditor}/>

        <Route path="/jobs" exact component={JobBrowser}/>
        <Route path="/jobs/:subtype" exact component={JobBrowser}/>
        <Route path="/job/:key" exact component={JobViewer}/>

        <Route path="/ships" exact component={ShipBrowser}/>
        <Route path="/ships/:subtype" exact component={ShipBrowser}/>
        <Route path="/ship/:key" exact component={ShipViewer}/>

        <Route path="/quests" exact component={QuestBrowser}/>
        <Route path="/quest/:key" exact component={QuestViewer}/>

        <Route path="/recipe-book/:key" exact component={RecipeBookViewer}/>

        <Route path="/trade-good/:key" exact component={TradeGoodViewer}/>

        <Route path="/about" exact component={About}/>

        <Route path="/news/:news_id/edit" exact component={NewsEditor}/>
        <Route path="/news/new" exact component={NewsEditor}/>

        <Route path="/wiki/:key" exact component={WikiViewer}/>
        <Route path="/wiki/:key/edit" exact component={WikiEditor}/>

        <Route path="/search/:query" exact component={Search}/>

        <Route path="/auth_callback" exact component={AuthCallback}/>
        <Route path="/logout_callback" exact component={LogoutCallback}/>
        <Route path="/profile" exact component={UserProfileEditor}/>

        {/* Catch-all */}
        <Route path="*" component={NotImplemented}/>
    </Switch>;
}
