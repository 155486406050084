import React from "react";
import Helmet from "react-helmet";
import {LinkableDocumentModel} from "../model/document";
import {upperCaseFirst} from "../utils";

interface IPageHeadersProps {
    title?: string;
    subject?: LinkableDocumentModel;
    action?: string;
}

interface IDescribable {
    description: string;
}

const PageHeaders: React.FC<IPageHeadersProps> = props => {
    let title = "Astrolabe - Uncharted Waters Online Database";
    if (props.title) {
        title = props.title + " - " + title;
    } else if (props.subject) {
        const subjectType = props.subject.type.split("-").map(tt => upperCaseFirst(tt)).join(" ");
        title = props.subject.name + " - " + subjectType + " - " + title;

        if (props.action) {
            title = props.action + ": " + title;
        }
    }

    let description = "Astrolabe is an unofficial Uncharted Waters Online Database (UWODB)";
    if (props.subject && asDescribable(props.subject).description) {
        description = asDescribable(props.subject).description + " | " + description;
    }

    return <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
    </Helmet>;
};

function asDescribable(linkable: LinkableDocumentModel): IDescribable {
    return (linkable as any) as IDescribable;
}

export default PageHeaders;
