import React from "react";
import styles from "./LevelEditor.module.scss";
import {IEditorComponentProps} from "./common";
import {Col, Row} from "react-bootstrap";
import {Spec} from "immutability-helper";
import {PlayerClass} from "../model/common";
import {upperCaseFirst} from "../utils";
import PlayerClassIcon from "../fragments/PlayerClassIcon";
import ScrollableIntegerInput from "../widgets/ScrollableIntegerInput";
import AstrolabeCheckbox from "../fragments/AstrolabeCheckbox";
import {FormControlLabel} from "@material-ui/core";

type ILevelEditorProps = IEditorComponentProps<number | null | undefined> & {
    clazz: PlayerClass
}

const LevelEditor: React.FC<ILevelEditorProps> = props =>{
    const {value, onStateUpdate, clazz} = props;

    const checkbox =
        <AstrolabeCheckbox value={valueToCheckbox(value)}
                           onChange={newValue => onStateUpdate({$set: toggleToValue(newValue)})}/>;

    const label = <div className={styles.clazzName}>
        <PlayerClassIcon pClass={clazz!}/>
        &nbsp;
        {upperCaseFirst(clazz!.toString())}
    </div>;

    return <Row className="mb-1">
        <Col xs={9} sm={5} md={4} lg={2}>
            <FormControlLabel control={checkbox}
                              label={label}/>
        </Col>

        <Col xs={3} sm={2} lg={1}>
            <ScrollableIntegerInput value={value}
                                    onChange={newValue => inputChanged(newValue, onStateUpdate)}
                                    disabled={!valueToCheckbox(value)}/>
        </Col>
    </Row>;
};

function toggleToValue(value: boolean): number | null {
    if (value) return 1;
    return null;
}

function valueToCheckbox(value: number | null | undefined): boolean {
    return !(value === undefined || value === null);
}

function inputChanged(input: number | null, onStateUpdate: (stateUpdate: Spec<number | null | undefined>) => void) {
    if (input === null) return;
    if (input < 1 || input > 90) return;

    onStateUpdate({$set: input});
}

export default LevelEditor;