import React, {useEffect, useState} from "react";
import styles from "./WikiEditor.module.scss";
import commonStyles from "../../styles/common.module.scss";
import {AppState} from "../../store/state";
import {useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router";
import WikiService from "../../services/WikiService";
import {Row, Col} from "react-bootstrap";
import AstrolabeMarkdown from "../../fragments/AstrolabeMarkdown";
import {Button, TextField} from "@material-ui/core";
import Loading from "../../fragments/Loading";

interface IWikiEditorMatch {
    key: string;
}

const WikiEditor: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [title, setTitle] = useState<string>("");
    const [content, setContent] = useState<string>("");

    const idToken = useSelector<AppState, string | undefined>(
        state => state !== undefined && state.userInfo !== undefined
            ? state.userInfo.idToken
            : undefined);

    const history = useHistory();
    const match = useRouteMatch<IWikiEditorMatch>();

    useEffect(() => {
        const {key} = match.params;

        if (key !== undefined) {
            WikiService.getWikiPage(key).then(wiki => {
                setTitle(wiki.title);
                setContent(wiki.content);
                setIsLoading(false);
            });
        }
    }, [match.params]);

    function handleSubmitClick() {
        let {key} = match.params;

        WikiService.updateWikiPage(key, title, content, idToken!)
            .then(newLocation => {
                if (newLocation) {
                    history.push(newLocation);
                }
            });
    }

    if (isLoading) {
        return <Loading/>;
    }

    return <>
        <Row>
            <Col>
                <Row>
                    <Col>
                        <TextField value={title}
                                   label="Page title"
                                   onChange={event => setTitle(event.target.value)}
                                   className={commonStyles.fullWidth}/>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <TextField multiline
                                   variant="outlined"
                                   className={commonStyles.fullWidth}
                                   InputProps={{classes: {input: styles.wikiEditor}}}
                                   label="Content (markdown)"
                                   value={content}
                                   onChange={event => setContent(event.target.value)}/>
                    </Col>
                </Row>

                <Row className="mt-3 mb-3">
                    <Col className="text-right">
                        <Button variant="outlined"
                                onClick={handleSubmitClick}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Col>

            <Col>
                <div className={styles.wikiPreview}>
                    <h1>{title}</h1>

                    <AstrolabeMarkdown source={content}/>
                </div>
            </Col>
        </Row>
    </>;
};

export default WikiEditor;
