import React, {useEffect, useState} from "react";
import {Redirect, useLocation} from "react-router";
import AuthService from "../services/AuthService";
import {useDispatch} from "react-redux";
import {AUTHENTICATE_USER} from "../store/actions";
import Loading from "../fragments/Loading";

const AuthCallback: React.FC = () => {
    const [redirectTarget, setRedirectTarget] = useState<string | undefined>(undefined);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search) as any;
        const code = params.get("code");
        const state = params.get("state");

        if (code) {
            AuthService.authFromCode(code)
                .then(userInfo => {
                    dispatch({
                        type: AUTHENTICATE_USER,
                        userInfo: userInfo
                    })
                }).then(() => {
                    const nonceData = JSON.parse(localStorage.getItem(state)!);
                    localStorage.removeItem(state);
                    setRedirectTarget(nonceData.returnUrl);
                });
        }
    }, [dispatch, location.search]);

    if (redirectTarget !== undefined) {
        return <Redirect to={redirectTarget!}/>;
    } else {
        return <Loading/>;
    }
};

export default AuthCallback;