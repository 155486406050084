import React from "react";
import {
    faBriefcase,
    faCheckSquare,
    faChild,
    faCrown,
    faGraduationCap,
    faHeart,
    faInfoCircle
} from "@fortawesome/pro-regular-svg-icons";
import {faStar} from "@fortawesome/pro-duotone-svg-icons";
import styles from "./SkillViewer.module.scss";
import SkillService from "../../services/SkillService";
import {
    AideSkillModel,
    BaseSkillModel, BostonSkillModel,
    isPlayerSkill,
    LanguageSkillModel, OxfordSkillModel,
    PlayerSkillModel,
    ShipSkillModel,
    SkillModel
} from "../../model/skill";
import SkillLink from "../../fragments/SkillLink";
import {useLocation, useHistory} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import InlineLink from "../../fragments/InlineLink";
import PlayerClassIcon from "../../fragments/PlayerClassIcon";
import {faServicestack} from "@fortawesome/free-brands-svg-icons";
import {PlayerClass} from "../../model/common";
import PageHeaders from "../../widgets/PageHeaders";
import InfoPanel from "../../fragments/InfoPanel";
import {canRender} from "../../hoc/CanRender";
import Level from "../../fragments/Level";
import Ducats from "../../fragments/Ducats";
import AideDuty from "../../fragments/AideDuty";
import {useSelector} from "react-redux";
import {AppState} from "../../store/state";
import {canEdit} from "../../permissions";
import {withObservableDataLoader, IDataProps} from "../../hoc/DataLoader";
import AssetImage from "../../fragments/AssetImage";

const SkillViewer : React.FC<IDataProps<SkillModel>> = props => {
    const skill = props.data;

    const canEditSkill = useSelector<AppState, boolean>(
        state => state && canEdit(state.userInfo)
    );

    const history = useHistory();
    const location = useLocation();

    if (!skill) return null;

    const skillIcon = <AssetImage item={skill}/>;

    return <>
        <PageHeaders subject={skill}/>

        <InfoPanel title={skill.name}
                   icon={skillIcon}
                   showEditButton={canEditSkill}
                   onEditButtonClick={() => history.push(location.pathname + "/edit")}>
            <Row>
                {/* Left column - description and teachers */}
                <Col md={7}>
                    <DescriptionSection data={skill}/>

                    <RequirementsSection data={skill}/>

                    <TeachersSection data={skill}/>
                </Col>

                {/* Right column - vigour, requirements, aide duties */}
                <Col md={5} className="mt-3 mt-md-0">
                    <VigourSection data={skill}/>

                    <RefinementEffectsSection data={skill}/>

                    <JobsSection data={skill}/>

                    <AideDutySection data={skill}/>

                    <OptionalOnShipsSection data={skill}/>

                    <AcademicJobSection data={skill}/>

                    <AffectsSkillsSection data={skill}/>

                    <AffectingSkillsSection data={skill}/>
                </Col>
            </Row>
        </InfoPanel>
    </>;
};

const DescriptionSection: React.FC<{data: BaseSkillModel}> = props => {
    const {description} = props.data;

    return <InfoPanelSection icon={faInfoCircle} text="Description">
        {description}
    </InfoPanelSection>;
};

const JobsSection = canRender<BaseSkillModel, PlayerSkillModel>(
    props => {
        const {expertJobs, favouringJobs} = props.data;

        return <InfoPanelSection icon={faBriefcase} text="Jobs">
            {expertJobs.map(j => <Row key={j.key}>
                <Col>
                    <FontAwesomeIcon icon={faStar} className={styles.expSkill} title="Expert skill"/>
                    &nbsp;
                    <PlayerClassIcon pClass={j.subtype!}/>
                    &nbsp;
                    <InlineLink to={j} hideIcon/>
                </Col>
            </Row>)}

            {favouringJobs.map(j => <Row key={j.key}>
                <Col>
                    <FontAwesomeIcon icon={faStar} className={styles.favSkill} title="Favoured skill"/>
                    &nbsp;
                    <PlayerClassIcon pClass={j.subtype!}/>
                    &nbsp;
                    <InlineLink to={j} hideIcon/>
                </Col>
            </Row>)}

            {expertJobs.length + favouringJobs.length === 0 && <Row>
                <Col>
                    None
                </Col>
            </Row>}
        </InfoPanelSection>;
    },
    isPlayerSkill
);

const TeachersSection = canRender<BaseSkillModel, PlayerSkillModel | LanguageSkillModel>(
    props => {
        const {teachers} = props.data;

        return <InfoPanelSection icon={faGraduationCap} text="Teachers">
            {teachers.map((teacher, i) => <Row key={i}>
                <Col>
                    {teacher.location} &mdash; {teacher.person}
                </Col>
            </Row>)}

            {teachers.length === 0 && <Row>
                <Col>
                    None known
                </Col>
            </Row>}
        </InfoPanelSection>;
    },
    isPlayerSkill
);


const VigourSection = canRender<BaseSkillModel, PlayerSkillModel | ShipSkillModel>(
    props => {
        const skill = props.data;

        return <InfoPanelSection icon={faHeart} text="Vigour">
            {skill.vigour || 'Auto effect'}
        </InfoPanelSection>;
    },
    skill => isPlayerSkill(skill) || skill.subtype === 'ship'
);

const RefinementEffectsSection = canRender<BaseSkillModel, PlayerSkillModel>(
    props => {
        let {refinementEffects} = props.data;
        if (refinementEffects === undefined) refinementEffects = [];

        refinementEffects = [
            '+2 to skill rank',
            '20% less proficiency required to rank up',
            ...refinementEffects];

        return <InfoPanelSection icon={faCrown} text="Refinement effects">
            <ul className="mb-0">
                {refinementEffects.map((effect, idx) => <li key={idx}>
                    {effect}
                </li>)}
            </ul>
        </InfoPanelSection>;
    },
    isPlayerSkill,
    skill => skill.refinementPossible === true
);

const RequirementsSection = canRender<BaseSkillModel, PlayerSkillModel | ShipSkillModel>(
    props => {
        const skill = props.data;

        const sectionTitle = skill.subtype === 'ship' ?
            "Usage requirements" :
            "Acquisition requirements";

        return <InfoPanelSection icon={faCheckSquare} text={sectionTitle}>
            {skill.subtype !== 'ship' && <Row>
                <Col xs={4} lg={3}>
                    <Level clazz={PlayerClass.Adventure} level={skill.advReq}/>
                </Col>
                <Col xs={4} lg={3}>
                    <Level clazz={PlayerClass.Trade} level={skill.tradeReq}/>
                </Col>
                <Col xs={4} lg={3}>
                    <Level clazz={PlayerClass.Battle} level={skill.battleReq}/>
                </Col>
                <Col lg={3}>
                    <Ducats amount={skill.cost}/>
                </Col>
            </Row>}

            <Row>
                {skill.requiredSkills.map((rs, i) =>
                    <Col xs={3} key={i}>
                        <SkillLink to={rs} showName={false}/>
                    </Col>
                )}
            </Row>
        </InfoPanelSection>;
    },
    skill => isPlayerSkill(skill) || skill.subtype === 'ship'
);

const AideDutySection = canRender<BaseSkillModel, AideSkillModel>(
    props => {
        const {aideDuty} = props.data;

        return <InfoPanelSection icon={faChild} text="Aide duty">
            <AideDuty duty={aideDuty}/>
        </InfoPanelSection>;
    },
    skill => skill.subtype === 'aide'
);

const OptionalOnShipsSection = canRender<BaseSkillModel, ShipSkillModel>(
    props => {
        const {optionalOnShips} = props.data;

        return <InfoPanelSection icon={faServicestack} text="Optional on ships">
            {optionalOnShips.map(ship => <Row key={ship.key}>
                <Col>
                    <InlineLink to={ship}/>
                </Col>
            </Row>)}
        </InfoPanelSection>;
    },
    skill => skill.subtype === 'ship'
);

const AcademicJobSection = canRender<BaseSkillModel, BostonSkillModel>(
    props => {
        const {job} = props.data;

        return <InfoPanelSection icon={faBriefcase} text="Academic job">
            {job && <InlineLink to={job} hideIcon/>}
            {job === undefined && <>&mdash;</>}
        </InfoPanelSection>;
    },
    skill => skill.subtype === "boston"
);

const AffectsSkillsSection = canRender<BaseSkillModel, OxfordSkillModel | BostonSkillModel>(
    props => {
        const {affectsSkills} = props.data;

        if (affectsSkills === undefined || affectsSkills.length === 0) {
            return null;
        }

        return <InfoPanelSection icon={faGraduationCap} text="Affects skills">
            {affectsSkills.map(skill => <div key={skill.key}>
                <SkillLink to={skill}/>
            </div>)}
        </InfoPanelSection>
    },
    skill => skill.subtype === "oxford" || skill.subtype === "boston"
);

const AffectingSkillsSection = canRender<BaseSkillModel, PlayerSkillModel>(
    props => {
        const {affectingSkills} = props.data;

        if (affectingSkills === undefined || affectingSkills.length === 0) {
            return null;
        }

        return <InfoPanelSection icon={faGraduationCap} text="Affected by">
            {affectingSkills.map(skill => <div key={skill.key}>
                <SkillLink to={skill}/>
            </div>)}
        </InfoPanelSection>
    },
    skill => isPlayerSkill(skill) || skill.subtype === "aide"
);

export default withObservableDataLoader<SkillModel, {subtype: string, key: string}>(
    SkillViewer,
    props => SkillService.getSkillWithCache(props.match.params.subtype, props.match.params.key),
    (props, prevProps) =>
        props.match.params.subtype !== prevProps.match.params.subtype ||
        props.match.params.key !== prevProps.match.params.key
);
