import axios, {AxiosRequestConfig} from "axios";
import {Observable} from "rxjs";

export interface IBackendResponse<TResult> {
    data: TResult;
    location?: string;
}

class BackendService {
    private apiBaseUrl: string = process.env.REACT_APP_ASTROLABE_API_URL_BASE!;
    private apiCachedBaseUrl: string = process.env.REACT_APP_ASTROLABE_API_CACHE_URL_BASE!;

    public get<TResult>(path: string, authorizationToken?: string): Promise<IBackendResponse<TResult>> {
        return this.fetch<TResult>(path, "GET", undefined, authorizationToken);
    }

    public getCached<TResult>(path: string): Promise<IBackendResponse<TResult>> {
        return axios(this.apiCachedBaseUrl + path);
    }

    public getObservableWithCache<TResult>(path: string): Observable<IBackendResponse<TResult>> {
        return new Observable<IBackendResponse<TResult>>(subscriber => {
            this.getCached<TResult>(path)
                .then(response => subscriber.next(response));

            this.get<TResult>(path)
                .then(response => {
                    subscriber.next(response);
                    subscriber.complete();
                });
        });
    }

    public post<TResult>(path: string, data: any, authorizationToken?: string): Promise<IBackendResponse<TResult>> {
        return this.fetch(path, "POST", data, authorizationToken);
    }

    public put<TResult>(path: string, data: any, authorizationToken?: string): Promise<IBackendResponse<TResult>> {
        return this.fetch(path, "PUT", data, authorizationToken);
    }

    public patch<TResult>(path: string, data: any, authorizationToken?: string): Promise<IBackendResponse<TResult>> {
        return this.fetch(path, "PATCH", data, authorizationToken);
    }

    private fetch<TResult>(path: string,
                           method: ("GET" | "POST" | "PUT" | "PATCH"),
                           data?: any,
                           authorizationToken?: string): Promise<IBackendResponse<TResult>> {
        let headers: any = {
            "Content-Type": "application/json"
        };

        if (authorizationToken) {
            headers["Authorization"] = authorizationToken;
        }

        let init: AxiosRequestConfig = {
            method: method,
            headers: headers
        };

        if (data) {
            init.data = JSON.stringify(data);
        }

        return axios(this.apiBaseUrl + path, init)
            .then(response => ({
                    data: response.data as TResult,
                    location: response.headers["location"]
                }));
    }
}

export default (new BackendService());