import React from "react";
import styles from "./ArrayEditor.module.scss";
import {IArrayEditorComponentProps} from "./common";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {Button, IconButton, withStyles} from "@material-ui/core";

const ArrayEditor = <TElem extends {}>(props: IArrayEditorComponentProps<TElem>) => {
    const {value, onStateUpdate, elementEditor, elementEditorProps, newElementFactory} = props;

    const ElementComponent = elementEditor;

    return <>
        {value.map((element, idx) => <Row key={idx} className="mb-1">
            <Col>
                <div className={styles.row}>
                    <div className={styles.remove}>
                        <RemoveButton size="small"
                                    onClick={() => onStateUpdate({$splice: [[idx, 1]]})}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </RemoveButton>
                    </div>

                    <div className={styles.nested}>
                        <ElementComponent value={value[idx]}
                                          onStateUpdate={stateUpdate => onStateUpdate({[idx]: stateUpdate} as any)}
                                          {...elementEditorProps}/>
                    </div>
                </div>
            </Col>
        </Row>
        )}

        <Row>
            <Col>
                <AddButton onClick={() => onStateUpdate({$set: addElement(value, newElementFactory())})}>
                    <FontAwesomeIcon icon={faPlus}/>
                    &nbsp;
                    Add
                </AddButton>
            </Col>
        </Row>
    </>;
};

export default ArrayEditor;

function addElement<T>(array: T[] | null | undefined, element: T): T[] {
    if (array === null || array === undefined) {
        array = [];
    }

    return [...array, element];
}

const RemoveButton = withStyles({
    root: {
        width: "1rem",
        minWidth: "0"
    },
    label: {
        color: "#dd4444"
    }
})(IconButton);

const AddButton = withStyles({
    label: {
        color: "#22cc44",
        textTransform: "none"
    }
})(Button);
