import {LinkableDocumentModel} from "../model/document";
import styles from "./InlineLink.module.scss";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook,
    faAxe,
    faScrollOld,
    faFileAlt,
    faMap,
    faCubes, faUtensilsAlt, faFeatherAlt
} from "@fortawesome/pro-duotone-svg-icons";
import {faSearch, faBriefcase} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "./tooltips/Tooltip";
import {makeLink, upperCaseFirst} from "../utils";
import {Link} from "react-router-dom";
import {faServicestack} from "@fortawesome/free-brands-svg-icons";

interface IInlineLinkPropsByDoc {
    to: LinkableDocumentModel;

    hideIcon?: boolean;
    suppressTooltip?: boolean;

    className?: string;
}

interface IInlineLinkPropsByProps {
    type: string;
    subtype: string | undefined;
    dKey: string;
    name: string;

    hideIcon?: boolean;
    suppressTooltip?: boolean;

    className?: string;
}

const InlineLink: React.FC<IInlineLinkPropsByDoc | IInlineLinkPropsByProps> = (props) => {
    const showIcon = props.hideIcon === undefined || !props.hideIcon;
    const suppressTooltip = props.suppressTooltip === true;

    let target: LinkableDocumentModel = (props as IInlineLinkPropsByDoc).to;
    if (!target) {
        const pprops = props as IInlineLinkPropsByProps;
        target = {
            type: pprops.type,
            subtype: pprops.subtype,
            key: pprops.dKey,
            name: pprops.name
        };
    }

    const displayName = target.name || (target as any).title;

    return <span className={styles.inlineLink + (props.className ? " " + props.className : "")}>
        {showIcon && <span className={styles.inlineLinkIcon}>
            {typeToIcon(target.type)}
            &nbsp;
        </span>}

        <span className={styles.inlineLinkLink}>
            {suppressTooltip ?
                <Link to={makeLink(target)}>
                    {displayName}
                </Link>
                    :
                <Tooltip target={target}>
                    <Link to={makeLink(target)}>
                        {displayName}
                    </Link>
                </Tooltip>}
        </span>
    </span>;
};

export function typeToIcon(type: string) {
    const commonProps = {
        className: styles["il_" + type],
        title: upperCaseFirst(type)
    };

    switch (type) {
        case "skill":
            return <FontAwesomeIcon icon={faBook} {...commonProps}/>;
        case "job":
            return <FontAwesomeIcon icon={faBriefcase} {...commonProps}/>;
        case "quest":
            return <FontAwesomeIcon icon={faFileAlt} {...commonProps}/>;
        case "discovery":
            return <FontAwesomeIcon icon={faSearch} {...commonProps}/>;
        case "item":
            return <FontAwesomeIcon icon={faScrollOld} {...commonProps}/>;
        case "equipment":
            return <FontAwesomeIcon icon={faAxe} {...commonProps}/>;
        case "zone":
            return <FontAwesomeIcon icon={faMap} {...commonProps}/>;
        case "trade-good":
            return <FontAwesomeIcon icon={faCubes} {...commonProps}/>;
        case "consumable":
            return <FontAwesomeIcon icon={faUtensilsAlt} {...commonProps}/>;
        case "ship":
            return <FontAwesomeIcon icon={faServicestack} {...commonProps}/>;
        case "wiki":
            return <FontAwesomeIcon icon={faFeatherAlt} {...commonProps}/>;
        default:
            return null;
    }
}

export default InlineLink;