import {Button, Color} from "@material-ui/core";
import {Theme, withStyles} from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const ColoredOutlineButton = (color: Color) => withStyles((theme: Theme) => ({
    root: {
        color: color[300],
        backgroundColor: "rgba(0, 0, 0, 0)",
        textTransform: "none",
        transition: "color .15s ease-in-out,background-color .15s ease-in-out",

        '&:hover': {
            backgroundColor: color[800],
            color: grey[50]
        },
    }
}))(Button);

export default ColoredOutlineButton;