import axios from "axios";
import {mapToQueryString} from "../utils";
import jwt_decode from "jwt-decode";
import {UserInfo} from "../store/state";
import {AUTHENTICATE_USER} from "../store/actions";
import { Dispatch } from "redux";

export interface IAuthService {
    authFromCode(code: string): Promise<UserInfo>;

    getUserInfo(accessToken: string): Promise<any>;

    tryRefreshAuthAndDispatch(dispatch: Dispatch<any>): Promise<void>;
    tryRefreshAuth(): Promise<UserInfo | undefined>;
    refreshAuth(refreshToken: string): Promise<UserInfo>;
}

export interface IAuthToken {
    access_token: string;
    expires_in: number;
    id_token: string;
    refresh_token?: string;
    token_type: string;
}

class AuthService implements IAuthService {
    public authFromCode(code: string): Promise<UserInfo> {
        const authPayload = {
            grant_type: "authorization_code",
            code: code,
            client_id: process.env.REACT_APP_ASTROLABE_CLIENT_ID,
            redirect_uri: `${window.location.origin}/auth_callback`
        };

        return axios(`${process.env.REACT_APP_ASTROLABE_AUTH_URL_BASE}/oauth2/token`, {
            method: "POST",
            data: mapToQueryString(authPayload),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(result => result.data)
            .then(token => {
                localStorage.setItem("astrolabe_refreshToken", token.refresh_token);

                return userInfoFromToken(token);
            });
    }

    public getUserInfo(accessToken: string): Promise<any> {
        return axios(`${process.env.REACT_APP_ASTROLABE_AUTH_URL_BASE}/oauth2/userInfo`, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        }).then(result => result.data);
    }

    public tryRefreshAuthAndDispatch(dispatch: Dispatch<any>): Promise<void> {
        return this.tryRefreshAuth()
            .then(userInfo => {
                if (userInfo !== undefined) {
                    dispatch({
                        type: AUTHENTICATE_USER,
                        userInfo: userInfo
                    });
                }
            });
    }

    public tryRefreshAuth(): Promise<UserInfo | undefined> {
        const refreshToken = localStorage.getItem("astrolabe_refreshToken");

        if (refreshToken) {
            return this.refreshAuth(refreshToken)
                .then(userInfo => userInfo, () => {
                    localStorage.removeItem("astrolabe_refreshToken");
                    return undefined;
                });
        } else {
            return Promise.resolve(undefined);
        }
    }

    public refreshAuth(refreshToken: string): Promise<UserInfo> {
        return axios(`${process.env.REACT_APP_ASTROLABE_AUTH_URL_BASE}/oauth2/token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: mapToQueryString({
                grant_type: "refresh_token",
                client_id: process.env.REACT_APP_ASTROLABE_CLIENT_ID,
                refresh_token: refreshToken
            })
        })
            .then(result => result.data)
            .then(userInfoFromToken);
    }
}

function userInfoFromToken(token: IAuthToken): UserInfo {
    const jToken = jwt_decode(token.id_token) as any;

    return {
        userId: jToken["cognito:username"],
        displayName: jToken["custom:display_name"],
        idToken: token.id_token,
        accessToken: token.access_token,
        groups: jToken["cognito:groups"]
    };
}

export default (new AuthService());