import {UserInfo} from "./store/state";

export function canEdit(userInfo: UserInfo | undefined): boolean {
    if (userInfo === undefined) return false;
    if (userInfo.groups === undefined) return false;
    return userInfo.groups.includes("AstrolabeEditors");
}

export function canEditWiki(userInfo: UserInfo | undefined): boolean {
    if (userInfo === undefined) return false;
    if (userInfo.groups === undefined) return false;
    return userInfo.groups.includes("AstrolabeWikiEditors");
}

export function canEditNews(userInfo: UserInfo | undefined): boolean {
    if (userInfo === undefined) return false;
    if (userInfo.groups === undefined) return false;
    return userInfo.groups.includes("AstrolabeNewsEditors");
}