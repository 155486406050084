import {Button, withStyles} from "@material-ui/core";
import React from "react";
import {manipulateColor} from "../utils";

const STYLE_CACHE: {[baseColor: string]: {selected: any, active: any}} = {};

interface ISmartButtonProps {
    baseColor: string;
    isSelected: boolean;
    onClick: () => void;
    className?: string;
    style?: any;
}

const SmartButton: React.FC<ISmartButtonProps> = props => {
    const {baseColor, isSelected, children} = props;

    const StyledButton = isSelected ?
        getOrCreateStyledButton(baseColor).selected :
        getOrCreateStyledButton(baseColor).active;

    const buttonProps = Object.assign({}, props) as any;
    delete buttonProps.isSelected;
    delete buttonProps.baseColor;

    return <StyledButton {...buttonProps}>
        <>{children}</>
    </StyledButton>;
};

export default SmartButton;

function getOrCreateStyledButton(baseColor: string) {
    if (STYLE_CACHE[baseColor] === undefined) {
        const styleSettings = [
            {
                textColor: "#fdfdfd",
                backgroundColor: manipulateColor(baseColor, 10),
                hoverColor: baseColor + "dd"
            },
            {
                textColor: "#fdfdfd",
                backgroundColor: baseColor +"44",
                hoverColor: baseColor +"99"
            }
        ];

        const styles = styleSettings.map(setting => withStyles({
            root: {
                color: setting.textColor,
                backgroundColor: setting.backgroundColor,

                "&:hover": {
                    backgroundColor: setting.hoverColor
                }
            },
            label: {
                textTransform: "none",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }
        })(Button));

        STYLE_CACHE[baseColor] = {
            selected: styles[0],
            active: styles[1]
        };
    }

    return STYLE_CACHE[baseColor];
}
