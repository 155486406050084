import React from "react";
import {IEditorComponentProps} from "./common";
import {AideDuty as AideDutyEnum, AllAideDuties} from "../model/aide";
import {MenuItem, Select} from "@material-ui/core";
import AideDuty from "../fragments/AideDuty";

type IAideDutyEditorProps = IEditorComponentProps<AideDutyEnum | undefined>;

const AideDutyEditor: React.FC<IAideDutyEditorProps> = props =>{
    const {value, onStateUpdate} = props;

    return <Select style={{width: "10rem"}}
                   value={unfixValue(value)}
                   onChange={event => onStateUpdate({$set: fixValue(event.target.value as any)})}>
        <MenuItem value={"None"}>None</MenuItem>

        {AllAideDuties.map(duty =>
            <MenuItem key={duty}
                      value={duty}>
                <AideDuty duty={duty}/>
            </MenuItem>)}
    </Select>;
};

function fixValue(value: string): AideDutyEnum | undefined {
    if (value === "None") return undefined;
    return value as AideDutyEnum;
}

function unfixValue(value: AideDutyEnum | undefined | null): string {
    if (value === undefined || value === null) return "None";
    return value;
}

export default AideDutyEditor;