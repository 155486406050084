import React, {ChangeEvent, useState} from "react";
import styles from "./SkillEditor.module.scss";
import {
    AideSkillModel, BaseSkillModel, BostonSkillModel,
    isPlayerSkill, OxfordSkillModel,
    PlayerSkillModel,
    ShipSkillModel,
    SkillModel,
    SkillRankModel
} from "../../model/skill";
import SkillService from "../../services/SkillService";
import {
    faBadgeCheck, faBriefcase,
    faCheckSquare, faChild,
    faCrown,
    faGraduationCap,
    faHeart,
    faInfoCircle
} from "@fortawesome/pro-regular-svg-icons";
import {PersonLocationModel, PlayerClass} from "../../model/common";
import update, {Spec} from 'immutability-helper';
import TextEditor from "../../editors/TextEditor";
import LevelEditor from "../../editors/LevelEditor";
import DucatsEditor from "../../editors/DucatsEditor";
import {withPromiseDataLoader, IDataProps} from "../../hoc/DataLoader";
import InfoPanel from "../../fragments/InfoPanel";
import {ButtonGroup, FormControlLabel, Input} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import InfoPanelSection from "../../fragments/InfoPanelSection";
import ArrayEditor from "../../editors/ArrayEditor";
import SkillRankEditor from "../../editors/SkillRankEditor";
import PersonLocationEditor from "../../editors/PersonLocationEditor";
import EditorNotice from "../../fragments/EditorNotice";
import BooleanEditor from "../../editors/BooleanEditor";
import {AppState} from "../../store/state";
import {useSelector} from "react-redux";
import {navigate} from "../../utils";
import {PageHeaders, ScrollableIntegerInput} from "../../widgets";
import AideDutyEditor from "../../editors/AideDutyEditor";
import JobSelector from "../../widgets/JobSelector";
import ColoredOutlineButton from "../../fragments/ColoredOutlineButton";
import {amber, teal} from "@material-ui/core/colors";
import {Col} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import AstrolabeCheckbox from "../../fragments/AstrolabeCheckbox";
import {IEditorComponentProps} from "../../editors/common";
import SkillSelector from "../../widgets/SkillSelector";
import AssetImage from "../../fragments/AssetImage";

type StateUpdate = (stateUpdate: Spec<SkillModel>) => void;

const SkillEditor: React.FC<IDataProps<SkillModel>> = props => {
    const {data} = props;

    const idToken = useSelector<AppState, string | undefined>(
        state => state && state.userInfo
            ? state.userInfo.idToken
            : undefined);

    const [skill, setSkill] = useState<SkillModel>(data);
    const [summary, setSummary] = useState<string>("");

    const history = useHistory();

    const skillIcon = <AssetImage item={skill}/>;

    function updateState(stateUpdate: Spec<SkillModel>) {
        setSkill(update<SkillModel>(skill, stateUpdate));
    }

    function putSkill() {
        skill.revisionInfo = {summary};
        SkillService.putSkill(skill, idToken!)
            .then(() => navigate(history, "skill", skill.subtype, skill.key));
    }

    const SaveButton = ColoredOutlineButton(teal);
    const CancelButton = ColoredOutlineButton(amber);

    return <>
        <PageHeaders subject={skill} action="Editing"/>

        <InfoPanel title={skill.name} icon={skillIcon}>
            <InfoPanel.Part>
                <InfoPanelSection text="Description"
                                  icon={faInfoCircle}>
                    <TextEditor textarea
                                value={skill.description}
                                onStateUpdate={su => updateState({description: su})}/>
                </InfoPanelSection>

                {(isPlayerSkill(skill) || skill.subtype === "ship") &&
                    renderVigourSection(skill as PlayerSkillModel | ShipSkillModel, updateState)}

                {isPlayerSkill(skill) &&
                    renderRequirementsSection(skill as PlayerSkillModel, updateState)}

                {(isPlayerSkill(skill) || skill.subtype === "ship") &&
                    renderPrerequisitesSection(skill as PlayerSkillModel | ShipSkillModel, updateState)}

                {isPlayerSkill(skill) &&
                    renderTeachersSection(skill as PlayerSkillModel, updateState)}

                {isPlayerSkill(skill) &&
                    renderRefinementSection(skill as PlayerSkillModel, updateState)}

                {skill.subtype === "aide" &&
                    renderAideDutySection(skill, updateState)}

                {skill.subtype === 'boston' &&
                    renderAcademicJobSection(skill, updateState)}

                {(skill.subtype === 'oxford' || skill.subtype === 'boston') &&
                    renderAffectedSkillsSection(skill, updateState)}
            </InfoPanel.Part>

            <InfoPanel.Part innerClassName={styles.summaryContainer}>
                <Input value={summary}
                       placeholder="Summary of changes"
                       className={styles.summary}
                       onChange={(ev: ChangeEvent<HTMLInputElement>) => setSummary(ev.target.value)}/>

                <ButtonGroup>
                    <SaveButton
                            onClick={putSkill}>
                        Save
                    </SaveButton>

                    <CancelButton onClick={() => navigate(history, "skill", skill.subtype, skill.key)}>
                        Cancel
                    </CancelButton>
                </ButtonGroup>
            </InfoPanel.Part>
        </InfoPanel>
    </>;
};

function renderVigourSection(skill: PlayerSkillModel | ShipSkillModel,
                             updateState: StateUpdate) {
    return <InfoPanelSection text="Vigour"
                             icon={faHeart}>

        <Row>
            <Col xs={9} sm={5} md={4} lg={2}>
                <FormControlLabel control={
                    <AstrolabeCheckbox value={skill.vigour === undefined || skill.vigour === null}
                                       onChange={newValue => updateState({vigour: {
                                           $set: newValue ? undefined : 10
                                       }})}/>}
                                  label="Auto effect"/>
            </Col>

            <Col xs={3} sm={2} lg={1}>
                {skill.vigour !== null && skill.vigour !== undefined &&
                <ScrollableIntegerInput value={skill.vigour}
                                        placeholder="Vigour"
                                        onChange={newValue =>
                                            newValue! >= 1 && newValue! <= 100 &&
                                            updateState({vigour: {$set: newValue}})}/>}
            </Col>
        </Row>
    </InfoPanelSection>;
}

function renderRequirementsSection(skill: PlayerSkillModel,
                                   updateState: StateUpdate) {
    return <InfoPanelSection text="Acquisition requirements"
                             icon={faCheckSquare}>
        <LevelEditor clazz={PlayerClass.Adventure}
                     value={skill.advReq}
                     onStateUpdate={su => updateState({advReq: su})}/>
        <LevelEditor clazz={PlayerClass.Trade}
                     value={skill.tradeReq}
                     onStateUpdate={su => updateState({tradeReq: su})}/>
        <LevelEditor clazz={PlayerClass.Battle}
                     value={skill.battleReq}
                     onStateUpdate={su => updateState({battleReq: su})}/>
        <DucatsEditor value={skill.cost}
                      onStateUpdate={su => updateState({cost: su})}/>
    </InfoPanelSection>;
}

function renderPrerequisitesSection(skill: PlayerSkillModel | ShipSkillModel, updateState: StateUpdate) {
    return <InfoPanelSection text="Skill requirements"
                             icon={faBadgeCheck}>
        <ArrayEditor<SkillRankModel>
            value={skill.requiredSkills}
            onStateUpdate={su => updateState({requiredSkills: su})}
            elementEditor={SkillRankEditor}
            newElementFactory={() => ({} as SkillRankModel)}/>
    </InfoPanelSection>;
}

function renderTeachersSection(skill: PlayerSkillModel, updateState: StateUpdate) {
    return <InfoPanelSection text="Teachers"
                             icon={faGraduationCap}>
        <ArrayEditor<PersonLocationModel>
            value={skill.teachers}
            onStateUpdate={su => updateState({teachers: su})}
            elementEditor={PersonLocationEditor}
            newElementFactory={() => ({person: "", location: ""})}/>
    </InfoPanelSection>;
}

function renderRefinementSection(skill: PlayerSkillModel, updateState: StateUpdate) {
    return <InfoPanelSection text="Refinement"
                             icon={faCrown}>
        <EditorNotice>
            All refinable skills gain the following perks:
            <ul>
                <li>Permanent +2 to skill rank</li>
                <li>20% less skill proficiency required to rank up</li>
            </ul>
            Do not list them here, they will be displayed automatically. List only skill-specific effects.
        </EditorNotice>

        <BooleanEditor value={skill.refinementPossible || false}
                       onStateUpdate={su => updateState({
                           refinementPossible: su as any,
                           refinementEffects: {$set: (su as any).$set ? [""] : undefined}
                       } as any)}
                       label="Refinement possible"/>

        {skill.refinementPossible &&
            <ArrayEditor<string>
                value={skill.refinementEffects!}
                onStateUpdate={su => updateState({refinementEffects: su})}
                elementEditor={TextEditor}
                elementEditorProps={{placeholder: "Refinement effect"}}
                newElementFactory={() => ""}/>}
    </InfoPanelSection>;
}

function renderAideDutySection(skill: AideSkillModel, updateState: StateUpdate) {
    return <InfoPanelSection text="Aide duty" icon={faChild}>
        <AideDutyEditor value={skill.aideDuty}
                        onStateUpdate={stateUpdate => updateState({aideDuty: stateUpdate})}/>
    </InfoPanelSection>;
}

function renderAcademicJobSection(skill: BostonSkillModel, updateState: StateUpdate) {
    return <InfoPanelSection icon={faBriefcase} text="Academic job">
        <JobSelector value={skill.job}
                     onSelected={value => updateState({job: {$set: value}})}/>
    </InfoPanelSection>;
}

function renderAffectedSkillsSection(skill: OxfordSkillModel | BostonSkillModel, updateState: StateUpdate) {
    return <InfoPanelSection icon={faGraduationCap} text="Affected skills">
        <ArrayEditor<BaseSkillModel>
            value={skill.affectsSkills || []}
            elementEditor={SingleSkillSelector}
            newElementFactory={() => (null as unknown as BaseSkillModel)}
            onStateUpdate={su => updateState({affectsSkills: su})}/>
    </InfoPanelSection>
}

const SingleSkillSelector: React.FC<IEditorComponentProps<BaseSkillModel>> = props => {
    const {onStateUpdate} = props;
    const skill = props.value;

    return <SkillSelector value={skill === undefined ? null : skill}
                   subtypes={["adventure", "trade", "battle", "aide"]}
                   placeholder="Choose skill"
                   onChange={selected =>
                       onStateUpdate({$set: selected!})}/>;
};

export default withPromiseDataLoader<SkillModel, {subtype: string, key: string}>(
    SkillEditor,
    props => SkillService.getSkill(props.match.params.subtype, props.match.params.key)
);
