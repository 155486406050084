import React, {useState} from "react";
import commonStyles from "../styles/common.module.scss";
import {DocumentModel} from "../model/document";
import SearchService from "../services/SearchService";
import styles from "./OmniSearchBar.module.scss";
import {useHistory} from "react-router-dom";
import {makeLink} from "../utils";
import {typeToIcon} from "../fragments/InlineLink";
import {ALLOWED_SEARCH_TYPES} from "../model/search";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";

interface ISearchResultItem extends DocumentModel  {
    name?: string;
    title?: string;
}

const OmniSearchBar: React.FC = () => {
    const [value, setValue] = useState<ISearchResultItem | null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [foundItems, setFoundItems] = useState<ISearchResultItem[]>([]);

    const history = useHistory();

    function handleInputChange(newInput: string) {
        setInputValue(newInput);

        if (newInput.length < 3) {
            setFoundItems([]);
        } else {
            SearchService.search(newInput, ALLOWED_SEARCH_TYPES)
                .then(items => {
                    setFoundItems(items);
                });
        }
    }

    function handleKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Escape") {
            setValue(null);
            setInputValue("");
            setFoundItems([]);
        } else if (event.key === "Enter") {
            if (inputValue.length >= 3) {
                setFoundItems([]);

                history.push("/search/" + inputValue);
            }
        }
    }

    return <Autocomplete value={value}
                         inputValue={inputValue}
                         options={value === null ? foundItems : []}
                         renderInput={params =>
                             <TextField className={commonStyles.fullWidth}
                                        placeholder="Search for (just about) anything..."
                                        onChange={event => handleInputChange(event.target.value)}
                                        {...params}/>}
                         getOptionLabel={item => (item.name || item.title || "")!}
                         renderOption={item => {
                             let type_str = item.type;
                             if (item.subtype) type_str += '-' + item.subtype;

                             return <>
                                 <div className={styles.optionIcon}>{typeToIcon(item.type)}</div>
                                 <div className={styles.optionName}>{item.name || item.title}</div>
                                 <div className={styles.optionType}>({type_str})</div>
                             </>;
                         }}
                         forcePopupIcon={false}
                         open={value === null && foundItems.length > 0}
                         onKeyUp={handleKeyUp}
                         onChange={(event: any, value: ISearchResultItem | null) => {
                             setValue(value);
                             if (value === null) return;

                             setInputValue("");
                             setFoundItems([]);

                             history.push(makeLink(value));
                         }}
                         classes={{
                             option: styles.option
                         }}
                         onBlur={() => {
                             if (value !== null) setValue(null);
                         }}
                         />;
};

export default OmniSearchBar;