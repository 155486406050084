import React from "react";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import styles from "./InfoPanelSection.module.scss";

interface IInfoPanelSectionPropsWithHeader {
    noHeader?: false;

    icon: IconProp;
    text: string;
    faClass?: string;
    faMask?: IconProp;
    faTransform?: any;
}

interface IInfoPanelSectionPropsWithoutHeader {
    noHeader: true;
}

type IInfoPanelSectionProps = IInfoPanelSectionPropsWithHeader | IInfoPanelSectionPropsWithoutHeader;

const InfoPanelSection: React.FC<IInfoPanelSectionProps> = props => {
    const showHeader = !props.noHeader;

    return <>
        {showHeader && <InfoPanelSectionHeader {...props as IInfoPanelSectionPropsWithHeader}/>}

        <Row className={styles.infoPanelContentRow}>
            <Col>
                {props.children}
            </Col>
        </Row>
    </>;
};

const InfoPanelSectionHeader: React.FC<IInfoPanelSectionPropsWithHeader> = props => {
    const faProps: any = {
        icon: props.icon,
        className: `${props.faClass} fa-fw`
    };

    if (props.faMask) {
        faProps.mask = props.faMask
    }

    if (props.faTransform) {
        faProps.transform = props.faTransform;
    }

    return <Row className={styles.infoPanelSubheaderRow}>
        <Col>
            <div className={styles.infoPanelSubheader}>
                <FontAwesomeIcon {...faProps}/>
                <span className={styles.infoPanelSubheaderTitle}>{props.text}</span>
            </div>
        </Col>
    </Row>;
};

export default InfoPanelSection;