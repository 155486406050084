import React from "react";
import {IEditorComponentProps} from "./common";
import {BaseSkillModel, SkillRankModel} from "../model/skill";
import {Col, Row} from "react-bootstrap";
import SkillSelector from "../widgets/SkillSelector";
import ScrollableIntegerInput from "../widgets/ScrollableIntegerInput";
import {Spec} from "immutability-helper";

type ISkillRankEditorProps = IEditorComponentProps<SkillRankModel>;

const SkillRankEditor: React.FC<ISkillRankEditorProps> = props => {
    const {onStateUpdate} = props;
    const {skill, rank} = props.value;

    return <Row>
        <Col xs={8} sm={9} md={4} lg={3}>
            <SkillSelector value={skill === undefined ? null : skill}
                           subtypes={["adventure", "trade", "battle"]}
                           placeholder="Choose skill"
                           onChange={selected =>
                               onStateUpdate({$set: {skill: selected!, rank: rank}})}/>
        </Col>

        <Col xs={3} sm={2} lg={1}>
            <ScrollableIntegerInput value={rank}
                                    placeholder="Rank"
                                    onChange={newRank => onRankChange(skill, newRank, onStateUpdate)}/>
        </Col>
    </Row>;
};

export default SkillRankEditor;

function onRankChange(skill: BaseSkillModel,
                      newRank: number | null,
                      onStateUpdate: (stateUpdate: Spec<SkillRankModel>) => void) {
    if (isValidRank(newRank)) {
        onStateUpdate({$set: {skill: skill, rank: newRank}})
    }
}

function isValidRank(rank: number | null) {
    if (rank === null) return true;
    return rank >= 1 && rank <= 20;
}