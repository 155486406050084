import React from "react";
import {AideDuty as AideDutyType} from "../model/aide";

interface IAideDutyProps {
    duty: AideDutyType | undefined | null;
    hideName?: boolean;
}

const AideDuty: React.FC<IAideDutyProps> = props => {
    const {duty, hideName} = props;

    if (!duty) {
        return <>&mdash;</>;
    }

    const icon = <img src={require(`../assets/aide-duty/${duty}.png`)}
                      alt={duty}
                      title={hideName ? duty : undefined}/>;

    if (hideName) {
        return icon;
    } else {
        return <>
            {icon}
            &nbsp;
            {duty}
        </>;
    }
};

export default AideDuty;