import React from "react";
import styles from "./StarDifficulty.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as starFull} from "@fortawesome/pro-solid-svg-icons";
import {faStar as starEmpty} from "@fortawesome/pro-regular-svg-icons";

const StarDifficulty: React.FC<{difficulty: number}> = props => {
    const difficulty = props.difficulty;

    return <span className={styles.starDifficulty}>
        {[...Array(difficulty)].map((i, ii) =>
            <FontAwesomeIcon icon={starFull} key={ii}/>
        )}

        {[...Array(10 - difficulty)].map((i, ii) =>
            <FontAwesomeIcon icon={starEmpty} key={ii}/>
        )}
    </span>;
};

export default StarDifficulty;