import {TradeGoodModel} from "../model/trade-good";
import BackendService from "./BackendService";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

interface ITradeGoodService {
    getTradeGood(key: string): Promise<TradeGoodModel>;

    getTradeGoodWithCache(key: string): Observable<TradeGoodModel>;
}

class TradeGoodService implements ITradeGoodService {
    public getTradeGood(key: string): Promise<TradeGoodModel> {
        return BackendService.get<TradeGoodModel>(`/trade-good/${key}`)
            .then(response => response.data);
    }

    public getTradeGoodWithCache(key: string): Observable<TradeGoodModel> {
        return BackendService.getObservableWithCache<TradeGoodModel>(`/trade-good/${key}`)
            .pipe(map(response => response.data));
    }
}

export default new TradeGoodService();