import ReactGA from "react-ga";

export const AnalyticsService = {
    initialize(): void {
        const trackingId = 'UA-111621207-1';
        ReactGA.initialize(trackingId);
    },

    identUser(userId: string): void {
        ReactGA.set({userId});
    },

    pageview(location: string): void {
        // console.log(`Pageview: ${location}`);

        ReactGA.pageview(location);
    },

    loginClicked(): void {
        ReactGA.event({
            category: 'User',
            action: 'Log in'
        });
    }
};
